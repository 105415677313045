import { GeoJsonCodec } from '@luciad/ria/model/codec/GeoJsonCodec'
import { FeatureModel } from '@luciad/ria/model/feature/FeatureModel'
import { MemoryStore } from '@luciad/ria/model/store/MemoryStore'
import { UrlStore } from '@luciad/ria/model/store/UrlStore'
import { LonLatPointFormat } from '@luciad/ria/shape/format/LonLatPointFormat'
import * as ShapeFactory from '@luciad/ria/shape/ShapeFactory'
import { FeatureLayer } from '@luciad/ria/view/feature/FeatureLayer'
import { GridLayer } from '@luciad/ria/view/grid/GridLayer'
import { LonLatGrid } from '@luciad/ria/view/grid/LonLatGrid'
import { LayerType } from '@luciad/ria/view/LayerType'
import { RasterTileSetLayer } from '@luciad/ria/view/tileset/RasterTileSetLayer'
import cfg from '../../../config'
import { layersStyling } from '../../../styles/palette'
import { CRS84_REFERENCE, WEB_MERCATOR_REFERENCE } from './bounds'
import { BackgroundType } from './index'
import { MapTilerTileSetModel } from './MapTilerTileSetModel'
import {
  makeAerodromePainter,
  makeDefaultDisplayNamePainter,
  makeDefaultPlanePainter,
  makeDirectorDisplayNamePainter,
  makeDirectorPlanePainter,
  makeRegulationPainter,
  makeReroutingPainter,
  makeTrajectoryPainter
} from './painters'
import { makeTrajectoryToPointShapeProvider } from './ShapeProviders'
import WMTSTileSetModel from './WMTSTileSetModel'

const {
  originLineStyle,
  lineStyle,
  originLabelStyle,
  labelStyle
} = layersStyling

/**
 * Pans luciad map
 * @param map
 * @param deltaX
 * @param deltaY
 * @param animate
 * @returns {luciad.util.Promise<any>}
 */

export function panMap (map, deltaX, deltaY = 0, animate = false) {
  const centerOfView = ShapeFactory.createPoint(null, [map.viewSize[0] / 2, map.viewSize[1] / 2])
  const offsetFromCenterOfView = centerOfView.copy()
  offsetFromCenterOfView.translate(deltaX, deltaY)
  return map.mapNavigator.pan({ targetLocation: centerOfView, toViewLocation: offsetFromCenterOfView, animate })
}

/**
 * @returns {*} background layer
 */
export function createBackgroundLayer (background) {
  switch (background) {
    case BackgroundType.AG_BLACK: {
      const model = _createAGTileSetModel(cfg.urls.background.arcGisBlack, 'Canvas_World_Black_Base')
      return new RasterTileSetLayer(model, { label: 'Black marble (WMTS)', layerType: LayerType.BASE })
    }
    case BackgroundType.AG_WORLD: {
      const model = _createAGTileSetModel(cfg.urls.background.arcGisWorld, 'Canvas_World_Base')
      return new RasterTileSetLayer(model, { label: 'Blue marble (WMTS)', layerType: LayerType.BASE })
    }
    case BackgroundType.MT_BASIC: {
      const model = _createMTTileSetModel(cfg.urls.background.mapTilerBasic)
      return new RasterTileSetLayer(model, { label: 'MapTiler basic', layerType: LayerType.BASE })
    }
    case BackgroundType.MT_SATELLITE: {
      const model = _createMTTileSetModel(cfg.urls.background.mapTilerSatellite)
      return new RasterTileSetLayer(model, { label: 'MapTiler satellite', layerType: LayerType.BASE })
    }
    default:
      throw new Error('Unsupported background type ' + background)
  }
}

function _createAGTileSetModel (url, layer) {
  return new WMTSTileSetModel({
    reference: WEB_MERCATOR_REFERENCE,
    bounds: ShapeFactory.createBounds(WEB_MERCATOR_REFERENCE,
      [-20037508.34278925, 40075016.6855785, -20037508.34278925, 40075016.6855785]),
    url: url,
    layer: layer,
    style: 'default',
    format: 'image/jpeg',
    tileMatrixSet: 'GoogleMapsCompatible',
    level0Columns: 1,
    level0Rows: 1,
    levelCount: 25,
    tileMatrices: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]
  })
}

function _createMTTileSetModel (url) {
  return new MapTilerTileSetModel({
    reference: WEB_MERCATOR_REFERENCE,
    bounds: ShapeFactory.createBounds(WEB_MERCATOR_REFERENCE,
      [-20037508.34278925, 40075016.6855785, -20037508.34278925, 40075016.6855785]),
    baseURL: url,
    levelCount: 21
  })
}

/**
 *
 * @returns {luciad.view.grid.GridLayer} Grid of Lat/Lon
 */

export function createLonLatGridLayer () {
  const settings = [
    { scale: 40000.0E-9, deltaLon: 1 / 60, deltaLat: 1 / 60 },
    { scale: 20000.0E-9, deltaLon: 1 / 30, deltaLat: 1 / 30 },
    { scale: 10000.0E-9, deltaLon: 1 / 10, deltaLat: 1 / 10 },
    { scale: 5000.0E-9, deltaLon: 1 / 2, deltaLat: 1 / 2 },
    { scale: 1000.0E-9, deltaLon: 1, deltaLat: 1 },
    { scale: 200.0E-9, deltaLon: 5, deltaLat: 5 },
    { scale: 20.0E-9, deltaLon: 10, deltaLat: 10 },
    { scale: 9.0E-9, deltaLon: 20, deltaLat: 20 },
    { scale: 5.0E-9, deltaLon: 30, deltaLat: 30 },
    { scale: 0, deltaLon: 45, deltaLat: 45 }
  ]

  const grid = new LonLatGrid(settings)
  grid.fallbackStyle = {
    labelFormat: new LonLatPointFormat({ pattern: 'lat(+DM),lon(+DM)' }),
    originLabelFormat: new LonLatPointFormat({ pattern: 'lat(+D),lon(+D)' }),
    originLineStyle, lineStyle, originLabelStyle, labelStyle
  }

  const labelFormat = new LonLatPointFormat({ pattern: 'lat(+D),lon(+D)' })
  grid.setStyle(grid.scales.indexOf(0), { labelFormat })
  grid.setStyle(grid.scales.indexOf(5.0E-9), { labelFormat })
  grid.setStyle(grid.scales.indexOf(9.0E-9), { labelFormat })
  grid.setStyle(grid.scales.indexOf(20.0E-9), { labelFormat })
  grid.setStyle(grid.scales.indexOf(200.0E-9), { labelFormat })

  return new GridLayer(grid, { label: 'Grid' })
}

/**
 *
 * @returns {luciad.view.feature.FeatureLayer} layer of regulated airports and airspaces
 */


export function createDirectorDisplayNameLayer () {
  return createDisplayNameLayer(makeDirectorDisplayNamePainter)
}

export function createDefaultDisplayNameLayer () {
  return createDisplayNameLayer(makeDefaultDisplayNamePainter)
}

function createDisplayNameLayer (displayNamePainterFactoryFunction) {
  // noinspection SpellCheckingInspection

  const store = new UrlStore({
    target: cfg.urls.displayNames,
  })

  const model = new FeatureModel(store, { reference: CRS84_REFERENCE })

  const layerProperties = {
    label: 'Display Names',
    selectable: false,
    painter: displayNamePainterFactoryFunction(),
    type: LayerType.DYNAMIC,
  }

  const layer = new FeatureLayer(model, layerProperties)
  layer.loadingStrategy.shouldUpdate = () => true

  return layer
}

export function createDirectorPlaneTrajectoryLayer (scale) {
  return createPlaneTrajectoryLayer(scale, makeDirectorPlanePainter)
}

export function createDefaultPlaneTrajectoryLayer (scale) {
  return createPlaneTrajectoryLayer(scale, makeDefaultPlanePainter)
}

export function createPlaneTrajectoryLayer (scale, planePainterFactoryFunction) {

  const trajectoryStore = new UrlStore({
    target: cfg.urls.flights,
    codec: new GeoJsonCodec()
  })

  const trajectoryModel = new FeatureModel(trajectoryStore, { reference: CRS84_REFERENCE })

  const layerProperties = {
    label: 'Trajectory',
    selectable: false,
    shapeProvider: makeTrajectoryToPointShapeProvider(trajectoryModel.reference),
    painter: planePainterFactoryFunction(scale),
    type: LayerType.DYNAMIC
  }

  return new FeatureLayer(trajectoryModel, layerProperties)
}

/**
 *
 * Timelapse related functions - attributes
 */



export function createTimelapseRegulationLayer () {

  const timelapseRegulationStore = new MemoryStore()
  const model = new FeatureModel(timelapseRegulationStore, { reference: CRS84_REFERENCE })

  let layerProperties = {
    label: 'Regulations',
    selectable: false,
    painter: makeRegulationPainter(),
    type: LayerType.DYNAMIC,
  }

  return { layer: new FeatureLayer(model, layerProperties), store: timelapseRegulationStore }
}

export function createTimelapseReroutingLayer () {

  const timelapseReroutingStore = new MemoryStore()
  const model = new FeatureModel(timelapseReroutingStore, { reference: CRS84_REFERENCE })

  let layerProperties = {
    label: 'Regulations',
    selectable: false,
    painter: makeReroutingPainter(),
    type: LayerType.DYNAMIC,
  }

  return { layer: new FeatureLayer(model, layerProperties), store: timelapseReroutingStore }
}

export function createTimelapseAerodromeLayer (scale) {

  const timelapseAerodromeStore = new MemoryStore()
  const model = new FeatureModel(timelapseAerodromeStore, { reference: CRS84_REFERENCE })

  let layerProperties = {
    label: 'Aerodromes',
    selectable: false,
    painter: makeAerodromePainter(scale),
    type: LayerType.DYNAMIC,
  }

  return { layer: new FeatureLayer(model, layerProperties), store: timelapseAerodromeStore }
}

/**
 *
 * @returns {luciad.view.feature.FeatureLayer} Layer of trajectories, can be animated by invalidating the shape provider
 *
 * The trajectories are displayed as planes or trajectories depending on the painter type parameter
 */

export function createTimelapseTrajectoryLayer (index, trajectoryColor) {
  const layerName = 'Trajectory' + index
  const layerProperties = {
    label: layerName,
    selectable: false,
    painter: makeTrajectoryPainter(trajectoryColor)
  }

  const timelapseTrajectoryStore = new MemoryStore()

  const trajectoryModel = new FeatureModel(timelapseTrajectoryStore, { reference: CRS84_REFERENCE })

  return { layer: new FeatureLayer(trajectoryModel, layerProperties), store: timelapseTrajectoryStore }
}