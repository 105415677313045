// @flow

/**
 * Returns the time in seconds.
 *
 * If the date is not specified, a new date will be created.
 *
 * @param date  the date for which to compute the time.
 *
 * @return {number} a UTC time in seconds.
 */
export function getTimeInSeconds (date: ?Date): number {
  date = date || new Date()
  return date.getTime() / 1000
}