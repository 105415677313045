import { GeoJsonCodec } from '@luciad/ria/model/codec/GeoJsonCodec'
import cfg from '../../../../config'
import { fetchWithRetry } from '../../../../services/api'
import TimelapseWorker from './timelapsepredict.worker'

const timelapseWorker = new TimelapseWorker()
export const geoJsonCodec = new GeoJsonCodec()

const trajectoryData = new Map()//contains cursor objects pointing to the decoded flights <regulationId, [flightFeature]>
const regulationData = new Map()//contains <regulationId, regulationFeature>

timelapseWorker.onmessage = function (e) {
  //decode the data - trajectory or regulations
  const decodedData = geoJsonCodec.decode({
    content: e.data.featureCollection,
    contentType: 'application/json;charset=utf-8'
  })

  if (e.data.type === 'fetchTrajectory.success') {
    const flights = []
    while (decodedData.hasNext()) {
      const flight = decodedData.next()
      flights.push(flight)
    }
    trajectoryData.set(e.data.regulationId, flights)
  } else if (e.data.type === 'fetchRegulations.success') {
    while (decodedData.hasNext()) {
      const regulation = decodedData.next()
      regulationData.set(regulation.id, regulation)
    }
  }
}

export function prepareData () {
  let regulations = []

  fetchWithRetry(cfg.urls.timelapse, Number.POSITIVE_INFINITY).then(regulationIds => {
    regulations = regulationIds
    console.debug('SIZE ' + regulationIds.length + ' result: ' + regulationIds)
    clearRemovedRegulations(regulationIds)
    //fetch flights for regulations
    regulationIds.forEach((regulationId) => fetchFlightsOfRegulation(regulationId))

    //prepare regulationData
    fetch24hRegulations()
  })

  return new Promise(resolve => {
    function checkDataCompleted () {
      if (trajectoryData.size !== 0 && trajectoryData.size === regulations.length) {
        //resolve the promise only after all the required data is gathered
        resolve(regulations)
      } else {
        window.setTimeout(checkDataCompleted, 1000)
      }
    }

    checkDataCompleted()
  })
}

function fetchFlightsOfRegulation (regulationId) {
  timelapseWorker.postMessage({
    type: 'fetchTrajectory',
    url: cfg.urls.timelapse + '/' + regulationId,
    regulationId: regulationId
  })
}

function fetch24hRegulations () {
  timelapseWorker.postMessage({ type: 'fetchRegulations', url: cfg.urls.regulations24h })
}

export function getFlightsForRegulation (regulationId) {
  return trajectoryData.get(regulationId)
}

function clearRemovedRegulations (mostSignificantRegulations) {
  const regulationIds = trajectoryData.keys()
  let regulationId = regulationIds.next()
  console.debug('most significant regulations ' + mostSignificantRegulations)
  while (!regulationId.done) {
    if (mostSignificantRegulations.indexOf(regulationId) === -1) {
      console.debug('Flights for ' + regulationId.value + 'are removed')
      trajectoryData.delete(regulationId.value)
    }
    regulationId = regulationIds.next()
  }
}

export function getRegulationFeature (regulationId) {
  return regulationData.get(regulationId)
}

export function get24hRegulations () {
  const regulations = []
  const iterator = regulationData.values()
  let next = iterator.next()
  while (!next.done) {
    regulations.push(next.value)
    next = iterator.next()
  }

  return regulations
}

export function anyExistingTrajectories () {
  return trajectoryData.size > 1 ? true : false
}