import { UrlTileSetModel } from '@luciad/ria/model/tileset/UrlTileSetModel'

export class MapTilerTileSetModel extends UrlTileSetModel {

  constructor (options) {
    super(options)
    this.modelDescriptor = Object.assign({}, this.modelDescriptor, { description: 'MapTiler Model' })
  }

  getTileURL (baseURL, tile) {
    return baseURL + '/' + tile.level + '/' + tile.x + '/' + ((Math.pow(2, tile.level)) - 1 - tile.y) + '.png?key=oQBMrAMv86hEe0BpGrje'
  }

}