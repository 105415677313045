import 'whatwg-fetch'
import pRetry from 'promise-retry'
import { API_OFFLINE, APIError } from '../common/errors'
import cfg from '../config'

/**
 * Fetches API, in case API fails, it will retry <cfg.retryAttempts> times, then throw error
 * @param uri
 * @returns {*}
 */

export function fetchWithRetry (uri, retryAttempts) {
  return pRetry((retry, number) => {
      return fetch(uri, {
          headers:
            {'Content-Type': 'application/json'}
        }
      )
        .then(res => {
          if (res.status >= 200 && res.status < 300) {
            return res.json()
          } else {
            throw new APIError(API_OFFLINE)
          }
        }).catch((err) => {
          if (number <= retryAttempts) {
            console.debug('Retry ' + number)
            return retry(err)
          } else {
            throw new APIError(API_OFFLINE)
          }
        })
    }
  )
}

export function postLog (uri, log) {
  fetch(uri, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(log)
  }).then(res => {
      if (res.status < 200 || res.status >= 300) {
        console.debug('Problem sending the log to the server. Response Status:' + res.status)
      }
    }
  )
}

export function registerClient (uri) {
  return fetch(uri, {
      headers:
        {'Content-Type': 'text/plain'}
    }
  )
    .then(res => {
      if (res.status >= 200 && res.status < 300) {
        return res.text()
      } else {
        console.debug('Problem receiving a client id. Response Status:' + res.status)
        return undefined
      }
    })
}

export function uploadFile (uri, file) {
  return new Promise((resolve, reject) => {
    const formData = new FormData()
    formData.append('file', file)

    fetch(uri, {
      // content-type header should not be specified!
      method: 'POST',
      body: formData
    })
      .then(res => {
        res.json().then(responseData => {
          if (res.status === 200) {
            resolve(responseData)
          } else {
            reject(responseData)
          }
        })
      })
  })
}

/**
 * Loads statistics
 * @returns {Promise}
 */

export function loadStatistics () {
  return fetchWithRetry(cfg.urls.statistics, cfg.retryAttempts)
}

export function loadTicker () {
  return fetchWithRetry(cfg.urls.ticker, cfg.retryAttempts)
}

export function loadVersion () {
  return fetchWithRetry(cfg.urls.version, cfg.retryAttempts)
}
