// @flow

import * as ShapeFactory from '@luciad/ria/shape/ShapeFactory'
import { ShapeType } from '@luciad/ria/shape/ShapeType'
import type { ParameterExpression } from '@luciad/ria/util/expression/Expression'
import * as ExpressionFactory from '@luciad/ria/util/expression/ExpressionFactory'
import { WebGLMap } from '@luciad/ria/view/WebGLMap'
import PropTypes from 'prop-types'
import * as React from 'react'
import { getRandomInt } from '../../../../common/utils'
import cfg from '../../../../config'
import bounds, { boundsEnum, COMMON_SPATIAL_REFERENCE, GLOBE_SPATIAL_REFERENCE } from '../bounds'
import { BackgroundType } from '../index'
import {
  createBackgroundLayer,
  createLonLatGridLayer,
  createTimelapseAerodromeLayer,
  createTimelapseRegulationLayer,
  createTimelapseReroutingLayer,
  createTimelapseTrajectoryLayer
} from '../layers'
import MapView from '../MapView'
import { constructMeasureLabelContent, subscribeToMeasureEvents, unsubscribeFromMeasureEvents } from '../painters'
import { calculateTimelapseSpeedUp } from '../TimeUtils'
import { animationList, getWorldFitBounds } from './TimelapseAnimationUtil'
import * as TimelapseDataProvider from './TimelapseDataProvider'
import { getLoadingLabel } from './TimelapseLabelUtil'

type Props = {
  id: string
}

const moment = require('moment-timezone')

export class TimelapseLiveMapController extends React.Component<Props> {

  /**
   attributes
   */
  map: WebGLMap
  regulationLayer: FeatureLayer
  reroutingLayer: FeatureLayer
  aerodromeLayer: FeatureLayer
  timelapseLayers: [FeatureLayer] = []
  timelapseReloadTimerID: *
  animationTimerID: *
  nmocMapState: Object
  otherMapState: Object
  lasttime: number
  //to break the animation frame loop when the data time frame is over for a trajectory layer.
  animationFrameId: number
  lengthOfTail: number = 125000
  timelapseStart: number
  timelapseEnd: number

  //attributes for rerouted/regulated flights timelapse
  colors = ['rgb(111, 60,2)', 'rgb(139, 41, 2)', 'rgb(124 , 11,2)']
  speedupReroutedFlights: number = 2500
  paintingScale: ParameterExpression<number>
  lastKnownMapScale: number = 0
  mapChangeHandle: ?RemoveHandle = null

  //attributes for remaning flights timelapse
  remainingFlightLoadTimerID: *
  //calculate the speed up value to timelapse 24h of data in 60 seconds
  speedupRemaningFlights: number = calculateTimelapseSpeedUp(55, 1)

  constructor (props: Props) {
    super(props)

    let self: any = this
    self.runRegulatedFlightsTimeLapse = this.runRegulatedFlightsTimeLapse.bind(this)
    self.startTimelapse = this.startTimelapse.bind(this)
  }

  componentDidMount () {
    this.initMap()
  }

  componentWillUnmount () {
    clearInterval(this.remainingFlightLoadTimerID)
    clearInterval(this.animationTimerID)
    clearInterval(this.timelapseReloadTimerID)
    unsubscribeFromMeasureEvents(this)
    if (this.mapChangeHandle) this.mapChangeHandle.remove()
    this.map.destroy()
  }

  initMap () {

    //fetch the timelapse data for the first time
    TimelapseDataProvider.updateTimelapseData()
    // and then initialize a timer to reload the timelapse data
    this.timelapseReloadTimerID = setInterval(() => {
      TimelapseDataProvider.updateTimelapseData()
    }, cfg.timelapseReloadIntervalInMilliSeconds)

    //create the map and add the background and grid layers
    this.map = new WebGLMap(this.props.id, { reference: GLOBE_SPATIAL_REFERENCE })
    this.map.layerTree.addChild(createBackgroundLayer(BackgroundType.AG_WORLD))
    this.map.layerTree.addChild(createLonLatGridLayer())

    //create and add the timelapse layers for scenarios and/or regulations
    for (let i = 0; i < 3; i++) {
      this.timelapseLayers[i] = createTimelapseTrajectoryLayer(i + 1, this.colors[i])
      this.map.layerTree.addChild(this.timelapseLayers[i].layer)
    }

    //create and add the trajectory layers for remaining flights
    this.timelapseLayers[4] = createTimelapseTrajectoryLayer(4)
    this.map.layerTree.addChild(this.timelapseLayers[4].layer, 'below', this.timelapseLayers[0].layer)

    //create and add the regulation layer
    this.regulationLayer = createTimelapseRegulationLayer()
    this.map.layerTree.addChild(this.regulationLayer.layer, 'top')

    //create and add the rerouting layer
    this.reroutingLayer = createTimelapseReroutingLayer()
    this.map.layerTree.addChild(this.reroutingLayer.layer, 'above', this.regulationLayer.layer)

    this.paintingScale = ExpressionFactory.numberParameter(0)
    this.aerodromeLayer = createTimelapseAerodromeLayer(this.paintingScale)
    this.map.layerTree.addChild(this.aerodromeLayer.layer, 'above', this.reroutingLayer.layer)

    //update scale when necessary
    this.mapChangeHandle = this.map.on('MapChange', function () {
      if (this.lastKnownMapScale !== this.map.mapScale[0]) {
        this.lastKnownMapScale = this.map.mapScale[0]
        this.paintingScale.value = this.lastKnownMapScale > 2e-7 ? 0 : 1
      }
    }, this)

    this.map.effects.atmosphere = false // to disable the blue sky

    //to display the time label  at the bottom
    this.createTimeDOMNode()

    //To display the prediction indication label at the top left
    this.createTrafficDataDOMNode()

    //add the loading panel to the dom
    this.createLoadingWindowDOMNode()

    //add the rerouting label to the dom
    this.createReroutingLabelDOMNode()

    subscribeToMeasureEvents(this)

    //save the state when it is zoomed in on the NMOC area
    const nmocBounds = getWorldFitBounds(bounds[boundsEnum.NMOC].bounds)
    this.map.mapNavigator.fit({ bounds: nmocBounds, animate: false })
    this.nmocMapState = this.map.saveState()

    //save another state
    this.map.mapNavigator.lookFrom(
      ShapeFactory.createPoint(COMMON_SPATIAL_REFERENCE, [20.742188, 34.957995, 1724465.501184974]),
      333.9450291492122,
      -45.058595215800377,
      0)
    this.otherMapState = this.map.saveState()

    this.startTimelapse()

    this.map.restoreState(this.nmocMapState, { animate: true })
  }

  startTimelapse () {
    this.displayLoadingPanelWithText(getLoadingLabel())
    if (TimelapseDataProvider.isTimelapseDataReady()) {
      this.displayLoadingPanel(false)
      if (TimelapseDataProvider.anyExistingTimelapse()) {
        const timelapsePeriod = TimelapseDataProvider.getTimelapsePeriod()
        this.timelapseStart = timelapsePeriod.start * 1000
        this.timelapseEnd = timelapsePeriod.end * 1000
        this.runRegulatedFlightsTimeLapse(0)
      } else {
        this.runRemainingFlightsTimeLapse()
      }
    } else {
      setTimeout(this.startTimelapse, 1000)
    }
  }

  runRegulatedFlightsTimeLapse (layerIndex) {
    window.cancelAnimationFrame(this.animationFrameId)

    const timelapseData = TimelapseDataProvider.getTimelapseData()[layerIndex]
    const measureFeature = timelapseData.measureFeature
    let bounds
    let fitMargin
    switch (timelapseData.type) {
      case 'REROUTING':
        this.reroutingLayer.store.put(measureFeature)
        this.aerodromeLayer.store.reload(timelapseData.aerodromes)
        bounds = getWorldFitBounds(TimelapseDataProvider.getShapeList(timelapseData.id).bounds)
        fitMargin = '1%'
        break
      case 'REGULATION':
        this.regulationLayer.store.put(measureFeature)
        bounds = getWorldFitBounds(measureFeature.shape.bounds)
        fitMargin = '70%'
        break
      default:
        break
    }

    let fitPromise = this.map.mapNavigator.fit({ bounds: bounds, fitMargin: fitMargin, animate: { duration: 4000 } })
    fitPromise.then(() => {
      this.lastDataTime = this.timelapseStart
      this.timelapseLayers[layerIndex].store.reload(timelapseData.flights)
      this.lasttime = Date.now()
      this.displayTimeLabel(true)
      this.animateTimeLabel(false)
      this.setTimeLabel(this.lastDataTime / 1000.0)
      this.scheduleTimeWindowRegulatedFlights(this.timelapseLayers[layerIndex].layer, layerIndex)
      setTimeout(() => {
        switch (timelapseData.type) {
          case 'REROUTING':
            //zoom to the aerodromes which are closer to the bottleneck area if aerodromes at both end are existing
            const shapeList = TimelapseDataProvider.getAerodromesShapeListToZoom(timelapseData.id)

            if (shapeList.shapeCount < 3) {
              this.map.mapNavigator.zoom({ factor: 0.6, animate: { duration: 4000 } })
            } else {
              fitPromise = this.map.mapNavigator.fit({
                bounds: getWorldFitBounds(shapeList.bounds),
                fitMargin: '70%',
                animate: { duration: 4000 }
              })
              fitPromise.then(() => {
                setTimeout(() => {
                  shapeList.addShape(measureFeature.shape)
                  fitPromise = this.map.mapNavigator.fit({
                    bounds: getWorldFitBounds(shapeList.bounds),
                    fitMargin: '70%',
                    animate: { duration: 3800 }
                  })
                }, 5000)
              })
            }
            break
          case 'REGULATION':
            if (measureFeature.shape.type === ShapeType.POLYGON) {
              this.map.mapNavigator.zoom({ factor: 0.6, animate: { duration: 4000 } })
            } else if (measureFeature.shape.type === ShapeType.POINT) {
              this.map.mapNavigator.zoom({ factor: 0.3, animate: { duration: 4000 } })
            }
            break
          default:
            break
        }
      }, 4700)
    })
  }

  clearLayers () {
    this.timelapseLayers.forEach((layer) => {
        layer.store.clear()
      }
    )

    this.aerodromeLayer.store.clear()
  }

  prepareTrajectoryLayerForRemainingFlights () {
    const remainingFlights = TimelapseDataProvider.getRemainingFlights()

    let i = 0
    const loadLimit = 500
    this.remainingFlightLoadTimerID = setInterval(() => {
      const startIndex = i * loadLimit
      let endIndex = (i + 1) * loadLimit
      endIndex = remainingFlights.length <= endIndex ? remainingFlights.length : endIndex

      if (remainingFlights.length > startIndex) {
        for (let j = startIndex; j < endIndex; j++) {
          this.timelapseLayers[4].store.put(remainingFlights[j])
        }
      } else {
        //loading is complete
        clearInterval(this.remainingFlightLoadTimerID)
      }
      i++
    }, 2000)
  }

  runRemainingFlightsTimeLapse () {
    window.cancelAnimationFrame(this.animationFrameId)
    this.aerodromeLayer.store.reload(TimelapseDataProvider.getAllAerodromes())
    this.prepareTrajectoryLayerForRemainingFlights()
    //hide any baloons or labels for reroutings that are displayed
    this.map.hideBalloon()
    this.displayReroutingLabel(false)

    this.setTimeLabel(this.timelapseStart / 1000.0)

    this.lasttime = Date.now()
    this.lastDataTime = this.timelapseStart

    this.scheduleTimeWindowRemainingFlights()

    this.animate(animationList[getRandomInt(animationList.length)])
  }

  animate (animations, index) {
    index = index ? index : 0
    if (index < animations.length) {
      const animation = animations[index]
      const promise = animation.function(this.map)
      promise.then(() => {
        setTimeout(() => {
          this.animate(animations, index + 1)
        }, animation.duration)
      })
    } else {
      const mapState = getRandomInt(2) === 1 ? this.nmocMapState : this.otherMapState
      this.map.restoreState(mapState, { animate: { duration: 4500 } })
    }
  }

  scheduleTimeWindowRegulatedFlights (trajectorylayer, layerIndex) {
    const currTime = Date.now()
    const deltaTime = (currTime - this.lasttime)
    this.lasttime = currTime
    let newTime = this.lastDataTime + (this.speedupReroutedFlights * deltaTime)
    this.lastDataTime = newTime
    if (newTime >= this.timelapseEnd) {//timelapse is over
      this.displayReroutingLabel(false)
      this.regulationLayer.store.clear()
      this.reroutingLayer.store.clear()
      this.aerodromeLayer.store.clear()
      this.animateTimeLabel(true)
      this.setTimeLabel(this.timelapseStart / 1000.0)
      setTimeout(() => {
        this.displayLoadingPanel(false)
        layerIndex++
        if (layerIndex < TimelapseDataProvider.numberOfTimelapse()) {
          this.runRegulatedFlightsTimeLapse(layerIndex)
        } else {
          this.runRemainingFlightsTimeLapse()
        }
      }, 1500)
      return
    }

    trajectorylayer.painter.timeWindow = [this.timelapseStart, (this.lastDataTime + this.lengthOfTail)]
    this.setTimeLabel(this.lastDataTime / 1000.0)
    this.animationFrameId = window.requestAnimationFrame(function () {
      this.scheduleTimeWindowRegulatedFlights(trajectorylayer, layerIndex)
    }.bind(this))
  }

  scheduleTimeWindowRemainingFlights () {
    const currTime = Date.now()
    const deltaTime = (currTime - this.lasttime)
    this.lasttime = currTime

    let newTime = this.lastDataTime + (this.speedupRemaningFlights * deltaTime)
    this.lastDataTime = newTime
    if (newTime >= this.timelapseEnd) {//timelapse is over for the remaining flights
      this.displayLoadingPanelWithText(getLoadingLabel())
      clearInterval(this.remainingFlightLoadTimerID)
      this.clearLayers()
      this.displayTimeLabel(false)
      setTimeout(() => {
        this.startTimelapse()
      }, 2500)
      return
    }

    this.timelapseLayers[4].layer.painter.timeWindow = [this.lastDataTime, (this.lastDataTime + this.lengthOfTail)]
    this.setTimeLabel(this.lastDataTime / 1000.0)
    this.animationFrameId = window.requestAnimationFrame(function () {
      this.scheduleTimeWindowRemainingFlights()
    }.bind(this))
  }

  /**
   * Creates the DOM node to display the clock on the map
   */
  createTimeDOMNode () {
    let timeLabel = document.createElement('div')
    timeLabel.setAttribute('id', 'onMapTimeLabel')
    timeLabel.innerHTML = '<span id="timelabel" class="upLeft3 noselect" ></span> '
    this.map.domNode.appendChild(timeLabel)
  }

  /**
   * Creates the DOM node to display the clock on the map
   */
  createTrafficDataDOMNode () {
    let liveTrafficLabel = document.createElement('div')
    liveTrafficLabel.setAttribute('id', 'onMapTrafficLabel')
    liveTrafficLabel.innerHTML = '<span id="livetrafficlabel" class="upLeft noselect" >Timelapse of today’s key<br>air traffic management events</span>'
    this.map.domNode.appendChild(liveTrafficLabel)
  }

  /**
   * Creates the DOM node to display text information in-between the timelapse transitions and when the data is being loaded
   */
  createLoadingWindowDOMNode () {
    const loadingPanel = document.createElement('div')
    loadingPanel.setAttribute('id', 'loadingpanel')
    loadingPanel.classList.add('loadingpanel')
    loadingPanel.style.textAlign = 'center'

    const infoPanel = document.createElement('div')
    infoPanel.setAttribute('id', 'infopanel')
    infoPanel.classList.add('infopanel')
    infoPanel.innerHTML = getLoadingLabel()
    loadingPanel.appendChild(infoPanel)

    this.map.domNode.appendChild(loadingPanel)
    this.displayLoadingPanel(true)
  }

  /**
   * Creates the DOM node to display text information on the rerouting scenario timelapse
   */
  createReroutingLabelDOMNode () {
    const loadingPanel = document.createElement('div')
    loadingPanel.setAttribute('id', 'reroutinglabelwindow')
    loadingPanel.classList.add('reroutinglabelwindow')
    loadingPanel.style.textAlign = 'center'

    const infoPanel = document.createElement('div')
    infoPanel.setAttribute('id', 'reroutinglabelpanel')
    infoPanel.classList.add('reroutinglabelpanel')
    loadingPanel.appendChild(infoPanel)

    this.map.domNode.appendChild(loadingPanel)
    this.displayReroutingLabel(false)
  }

  notifyMeasurePainted (feature) {
    // const layer = feature.measureType === 'Regulation' ? this.regulationLayer.layer : this.reroutingLayer.layer
    //for now  ballons are only displayed for regulations, the text explanation for the
    //reroutings are displayed in a window(div) on the side of the screen
    if (feature.measureType === 'Regulation') {
      const layer = this.regulationLayer.layer
      this.map.showBalloon({
        object: feature,
        layer: layer,
        contentProvider: constructMeasureLabelContent,
        panTo: false
      })
    } else {
      this.displayReroutingLabel(true, feature)
    }
  }

  setTimeLabel (currentTime) {
    document.getElementById('timelabel').innerHTML = moment.unix(currentTime).format('HH:mm') + ' CET'
  }

  animateTimeLabel (isAnimate) {
    // const timeLabelElement = document.getElementById('timelabel')
    const liveTrafficLabelElement = document.getElementById('livetrafficlabel')
    if (isAnimate) {
      liveTrafficLabelElement.classList.add('flip-scale-up-hor')
      // timeLabelElement.classList.add('flip-scale-up-hor')
    } else {
      liveTrafficLabelElement.classList.remove('flip-scale-up-hor')
      // timeLabelElement.classList.remove('flip-scale-up-hor')
    }
  }

  displayTimeLabel (isVisible) {
    const opacity = isVisible ? 1 : 0
    document.getElementById('timelabel').style.opacity = opacity
  }

  displayTrafficLabel (isVisible) {
    const opacity = isVisible ? 1 : 0
    document.getElementById('livetrafficlabel').style.opacity = opacity
  }

  displayLoadingPanel (isVisible) {
    const opacity = isVisible ? 1 : 0
    document.getElementById('loadingpanel').style.opacity = opacity
    this.displayTrafficLabel(!isVisible)
  }

  displayLoadingPanelWithText (text) {
    document.getElementById('loadingpanel').style.opacity = 1
    document.getElementById('infopanel').innerHTML = text
    this.displayReroutingLabel(false)
    this.displayTrafficLabel(false)
  }

  displayReroutingLabel (isVisible, feature) {
    const opacity = isVisible ? 1 : 0
    const reroutingLabelElement = document.getElementById('reroutinglabelpanel')
    reroutingLabelElement.classList.remove('reroutinglabelpanel')
    document.getElementById('reroutinglabelwindow').style.opacity = opacity
    if (opacity) {
      reroutingLabelElement.classList.add('reroutinglabelpanel')
      reroutingLabelElement.innerHTML = constructMeasureLabelContent(feature)
    }
  }

  render () {
    return (<MapView id={this.props.id}/>)
  }
}

TimelapseLiveMapController
  .propTypes = {
  id: PropTypes.string.isRequired
}
