import React from 'react'
import './slide-show-indicator.css'

type Props = {
    text: string
  }

export default class SlideShowIndicator extends React.Component<Props> {
    render () {
        return <div className="slide-show-indicator">
          <div className="slider">
            <span className={this.props.step ? 'selected': ''}></span>
            <span className={!this.props.step ? 'selected' : ''}></span>
          </div>
        </div>
      }
}