import * as React from 'react'
import './headline-news.css'

type Props = {
  text: string
}

export default class HeadlineNews extends React.Component<Props> {

  render () {
    return <div className="headline-news">
      <div className="header">HEADLINE NEWS</div>
      <div className="band">
        <div className="margins">
          <p style={{ animationDuration: this.props.text.length * 0.2 + 's' }}>{this.props.text}</p>
        </div>
      </div>
    </div>
  }
}