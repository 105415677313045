import { SET_OFFLINE, UPDATE_STATS, KPIS_STATS } from '../actions/updater'

const initialState = {
    planned: {
        value: 0,
        online: false
    },
    airborne: {
        value: 0,
        online: false
    },
    landed: {
        value: 0,
        online: false
    },
    totalDelay: {
      value: 0,
      online: false
    },
    totalFlights: {
        value: 0,
        online: false
    }
}

function updateIndicators (state, newState) {
  for (let key in newState) {
    const value = newState[key]
    if (value) {
      state[key].value = value
      state[key].online = true
    }
  }
}

export default function kpis (state = initialState, action) {
  switch (action.type) {
    case SET_OFFLINE:
      const newState = {...state}
      switch (action.payload) {
        case KPIS_STATS:
          newState.planned.online = false
          newState.landed.online = false
          newState.airborne.online = false
          newState.totalDelay.online = false
          break
        default:
          return state
      }
      return newState
    case UPDATE_STATS:
      const {planned, airborne, landed, totalDelay, totalFlights} = action.payload
      const newIndicators = {planned, airborne, landed, totalDelay, totalFlights}
      const indicators = {...state}
      updateIndicators(indicators, newIndicators)
      return {...state, ...indicators}
    default:
      return state
  }
}
