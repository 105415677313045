// @flow

import '@luciad/deployment-license' // Mission critical call to be placed first
import { DirectorMapController } from './DirectorMapController'
import { PlaneMapController } from './PlaneMapController'
import { TimelapseLiveMapController } from './timelapselive/TimelapseLiveMapController'
import { TimelapsePredictMapController } from './timelapsepredict/TimelapsePredictMapController'

export const TrajectoryPainterType = Object.freeze({
  TIMELAPSELIVEPAINTER: 'TimelapseLivePainter',
  TIMELAPSEPREDICTPAINTER: 'TimelapsePredictPainter',
  PLANEPAINTER: 'PlanePainter'
})

export const Map = PlaneMapController
export const DirectorMap = DirectorMapController
export const TimelapseLiveMap = TimelapseLiveMapController
export const TimelapsePredictMap = TimelapsePredictMapController

export const BackgroundType = Object.freeze({
  AG_BLACK: 'ArcGisBlack',
  AG_WORLD: 'ArcGisWorld',
  MT_BASIC: 'MapTilerBasic',
  MT_SATELLITE: 'MapTilerSatellite'
})
