import {
  KPIS_STATS,
  SET_OFFLINE,
  UPDATE_STATS
} from '../actions/updater'

const initialState = {
  accumulatedDelays: {
    value: [{
      id: 'Loading',
      title: 'Loading...',
      delay: 0,
      value: -1
    }],
    online: false
  },
  liveDelays: {
    value: [{
      id: 'Loading',
      title: 'Loading...',
      delay: 0,
      value: -1
    }],
    online: false
  }
}

const noDelayValue = [{
  id: 'Nodelays',
  title: 'European Network',
  delay: 0,
  value: -1
}]

export default function topTables(state = initialState, action) {
  switch (action.type) {
    case SET_OFFLINE:
      const newState = {
        ...state
      }
      switch (action.payload) {
        case KPIS_STATS:
          newState.accumulatedDelays.online = false
          newState.liveDelays.online = false
          break
        default:
          return state
      }
      return newState
    case UPDATE_STATS:
      const {
        accumulatedDelays, liveDelays
      } = action.payload
      const updatedState = {
        ...state
      }
      if (liveDelays) {
        updatedState.liveDelays.online = true
        updatedState.liveDelays.value = liveDelays.length > 0 ? liveDelays : noDelayValue
      }
      if (accumulatedDelays) {
        updatedState.accumulatedDelays.online = true
        updatedState.accumulatedDelays.value = accumulatedDelays.length > 0 ? accumulatedDelays : noDelayValue
      }
      return updatedState
    default:
      return state
  }
}