const regulationLabels = new Map()

const loadingLabel = [
  'Watch',
  'EUROCONTROL',
  'optimize the continent\'s busy skies',
];

// init the regulationLabels map
(function initLabelsMap () {

  const atcCapacityLabels = [
    'Around {nrImpactedFlights} flights could be regulated in {name}\\n to balance capacity with demand.',
    'Meanwhile at {name}, around {nrImpactedFlights} flights regulated\\n to optimise air traffic efficiency.',
    'Around {nrImpactedFlights} flights could be regulated at {name}\\n to manage delays resulting from low ATC capacity.',
    'Around {nrImpactedFlights} flights could be regulated\\n to minimise the effects of limited ATC capacity at {name}.'
  ]

  const aerodromeCapacityLabels = [
    '{name} is very busy today! \\n Around {nrImpactedFlights} flights could be regulated to minimise delay.',
    'Around {nrImpactedFlights} flights could be regulated at {name}\\n to balance capacity with demand.',
    'Around {nrImpactedFlights} flights could be regulated at {name}\\n to ensure seamless air traffic throughout the network.',
    'Around {nrImpactedFlights} flights could be regulated\\n to minimise the effects of limited aerodrome capacity at {name}.',
    'Meanwhile at {name}, around {nrImpactedFlights} flights\\n could be regulated to ensure the best network efficiency.'
  ]

  const aerodoromeServicesLabels = [
    'Due to a degradation of aerodrome services at {name},\\n around {nrImpactedFlights} flights could be regulated to minimise delay.'
  ]

  const specialEventLabels = [
    'At {name} around {nrImpactedFlights} flights could be regulated to minimise delay.',
    'Around {nrImpactedFlights} flights could be regulated at {name}\\n to ensure seamless air traffic throughout the network.',
    'Around {nrImpactedFlights} flights could be regulated at {name}\\n to optimise air traffic efficiency.'
  ]

  const airspaceManagementLabels = [
    '{name} is very active today!\\nAround {nrImpactedFlights} flights could be regulated to minimise delay.',
    'To balance capacity with demand,\\n around {nrImpactedFlights} flights could be regulated.',
    'Around {nrImpactedFlights} flights could be regulated \\n to ensure seamless air traffic in {name}.'
  ]

  const atcEquipmentLabels = [
    'Around {nrImpactedFlights} flights could be regulated\\n to manage the impact of ATC equipment issues experienced at {name}.'
  ]

  const atcRoutingLabels = [
    'Around {nrImpactedFlights} flights could be regulated at {name}\\n due to ATC routeings.'
  ]
  const atcActionLabels = [
    'Around {nrImpactedFlights} flights could be regulated at {name}\\n due to ATC industrial action. ',
    'Due to ATC industrial action at {name}, \\n around {nrImpactedFlights} flights could be regulated to minimise the disruption.'
  ]

  const atcStaffingLabels = [
    'Due to an ATC staffing issue at {name},\\n around {nrImpactedFlights} flights could be regulated to minimise disruptions.',
    'Due to an ATC staffing issue at {name},\\n around {nrImpactedFlights} flights could be regulated \\n to minimise the impact on the overall network performance.'
  ]

  const incidentAccidentLabels = [
    'Around {nrImpactedFlights} flights could be regulated at {name}.',
  ]

  const nonAtcActionLabels = [
    'Due to a non-ATC related industrial action at {name},\\n around {nrImpactedFlights} flights could be regulated to minimise disruptions.'
  ]

  const weatherLabels = [
    'Around {nrImpactedFlights} flights could be regulated at {name} due to weather.'
  ]

  const environmentalLbels = [
    'Around {nrImpactedFlights} flights could be regulated\\n due to an environmental issue near {name}'
  ]

  regulationLabels.set('ATC CAPACITY', atcCapacityLabels)
  regulationLabels.set('ACCIDENT INCIDENT', incidentAccidentLabels)
  regulationLabels.set('AERODROME SERVICES', aerodoromeServicesLabels)
  regulationLabels.set('AERODROME CAPACITY', aerodromeCapacityLabels)
  regulationLabels.set('ATC INDUSTRIAL ACTION', atcActionLabels)
  regulationLabels.set('NON ATC INDUSTRIAL ACTION', nonAtcActionLabels)
  regulationLabels.set('WEATHER', weatherLabels)
  regulationLabels.set('AIRSPACE MANAGEMENT', airspaceManagementLabels)
  regulationLabels.set('SPECIAL EVENT', specialEventLabels)
  regulationLabels.set('ATC ROUTINGS', atcRoutingLabels)
  regulationLabels.set('ATC STAFFING', atcStaffingLabels)
  regulationLabels.set('ATC EQUIPMENT', atcEquipmentLabels)
  regulationLabels.set('ENVIRONMENTAL ISSUES', environmentalLbels)
  regulationLabels.set('OTHERS', specialEventLabels)
})()

export function getLabelForRegulationReason (regulationReason) {
  const labelList = regulationLabels.get(regulationReason)
  if (labelList) {
    const labelListSize = labelList.length
    return labelListSize > 1 ? labelList[getRandomInt(labelListSize)] : labelList[0]
  }

  return ''
}

export function getLoadingLabel () {
  return '<h2>' + loadingLabel[0] + '</h2><h1>' + loadingLabel[1] + '</h1><h2>' + loadingLabel[2] + '</h2>'

}

export function getRandomInt (max) {
  return Math.floor(Math.random() * Math.floor(max))
}