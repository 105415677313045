import { LineType } from '@luciad/ria/geodesy/LineType'
import { ShapeType } from '@luciad/ria/shape/ShapeType'
import * as ExpressionFactory from '@luciad/ria/util/expression/ExpressionFactory'
import { FeaturePainter } from '@luciad/ria/view/feature/FeaturePainter'
import { ParameterizedPointPainter } from '@luciad/ria/view/feature/ParameterizedPointPainter'
import { TrajectoryPainter } from '@luciad/ria/view/feature/TrajectoryPainter'
import { PointLabelPosition } from '@luciad/ria/view/style/PointLabelPosition'
import * as Styler from '../../../common/styler'
import { getLabelForRegulationReason, getLabelForReroutingReason } from './timelapselive/TimelapseLabelUtil'

const moment = require('moment-timezone')

let observers = []

export function subscribeToMeasureEvents (observer) {
  observers.push(observer)
}

export function unsubscribeFromMeasureEvents (observer) {
  observers = observers.filter(subscriber => subscriber !== observer)
}

export function makeDirectorDisplayNamePainter () {
  return makeDisplayNamePainter(Styler.getDirectorAirspaceStyle, Styler.getDirectorAirportIcon, Styler.DIRECTOR_OFFSET_LABEL)
}

export function makeDefaultDisplayNamePainter () {
  return makeDisplayNamePainter(Styler.getAirspaceStyle, Styler.getAirportIcon, Styler.OFFSET_LABEL)
}

function makeDisplayNamePainter (polygonStyleFunctions, pointStyleFunctions, labelOffset) {
  let result = new FeaturePainter()

  result.paintBody = function (geocanvas, feature, shape, layer, map, paintState) {
    if (shape.type === ShapeType.SHAPE_LIST) {
      shape.geometries.forEach((geometry, index) => {
        const averageDelayRangeIndex = feature.properties.averageDelayRanges.length > 1 ? index : 0
        const averageDelay = feature.properties.averageDelayRanges[averageDelayRangeIndex]
        paintLocationBody(shape.geometries[index], averageDelay, geocanvas, polygonStyleFunctions, pointStyleFunctions)
      })
    } else {
      paintLocationBody(shape, feature.properties.averageDelayRanges[0], geocanvas, polygonStyleFunctions, pointStyleFunctions)
    }
  }

  result.paintLabel = function (labelcanvas, feature, shape, layer, map, paintState) {
    const label = ' <div class=\'locationLabel\'><span style=color:' + Styler.getLocationLabelColor(feature.id) + ';>' + feature.id + '</span></div>'
    if (shape.type === ShapeType.POLYGON) {
      labelcanvas.drawLabelInPath(label, shape, Styler.getPolygonLocationLabelStyle())
    } else {
      const labelPosition = shape.type === ShapeType.SHAPE_LIST ? PointLabelPosition.CENTER : PointLabelPosition.SOUTH
      labelcanvas.drawLabel(label, shape.focusPoint, Styler.getPointLocationLabelStyle(labelPosition, labelOffset))
    }

  }
  return result
}

function paintLocationBody (shape, averageDelay, geocanvas, polygonStyleFunctions, pointStyleFunctions) {
  if (shape.type === ShapeType.POLYGON) {
    geocanvas.drawShape(shape, polygonStyleFunctions(averageDelay))
  } else if (shape.type === ShapeType.POINT) {
    geocanvas.drawIcon(shape, pointStyleFunctions(averageDelay))
  }

}

export function makeRegulationPainter () {
  let result = new FeaturePainter()

  result.paintBody = function (geocanvas, feature, shape, layer, map, paintState) {
    const geometryType = feature.geometry.type
    const averageDelay = feature.properties.averageDelay

    observers.forEach(observer => {
      feature.measureType = 'Regulation'
      observer.notifyMeasurePainted(feature)
    })

    if (geometryType === ShapeType.POLYGON) {
      geocanvas.drawShape(feature.shape, Styler.getAirspaceStyle(averageDelay))
    } else if (geometryType === ShapeType.POINT) {
      geocanvas.drawIcon(shape, Styler.getAirportIcon(averageDelay))
    }
  }
  return result
}

export function makeReroutingPainter () {
  let result = new FeaturePainter()

  result.paintBody = function (geocanvas, feature, shape, layer, map, paintState) {
    const geometryType = feature.geometry.type

    observers.forEach(observer => {
      feature.measureType = 'Rerouting'
      observer.notifyMeasurePainted(feature)
    })

    if (geometryType === ShapeType.POLYGON) {
      geocanvas.drawShape(feature.shape, Styler.getReroutingAirspaceStyle())
    } /*else if (geometryType === ShapeType.POINT) {
      geocanvas.drawIcon(feature.shape, Styler.getAirportIcon(0))
    }*/
  }
  return result
}

export function makeAerodromePainter (scale) {
  let aerodromePainter = new ParameterizedPointPainter({
    attributes: {
      isArriving: function (feature, shape) {
        return feature.properties.elementType === 'ARRIVING' ? 1 : 0
      },
    },
    regular: {
      iconExpression: ExpressionFactory.ifThenElse(ExpressionFactory.eq(ExpressionFactory.attribute('isArriving'), ExpressionFactory.number(1)),
        (ExpressionFactory.ifThenElse(ExpressionFactory.gt(scale, ExpressionFactory.number(0)),
          ExpressionFactory.icon(Styler.getAerodromeIcon('SMALL', 'ARRIVING')),
          ExpressionFactory.icon(Styler.getAerodromeIcon('MEDIUM', 'ARRIVING')))),
        (ExpressionFactory.ifThenElse(ExpressionFactory.gt(scale, ExpressionFactory.number(0)),
          ExpressionFactory.icon(Styler.getAerodromeIcon('SMALL', 'DEPARTING')),
          ExpressionFactory.icon(Styler.getAerodromeIcon('MEDIUM', 'DEPARTING')))))
    }
  })

  //removing the aerodrome labels for now on DGCom's request
  // aerodromePainter.paintLabel = function (labelcanvas, feature, shape, layer, map, paintState) {
  //   const label = ' <div class=\'aerodromelabel\'><span style=color:' + Styler.getAerodromeLabelColor(feature.properties.elementType) + ';>' + feature.properties.name + '</span></div>'
  //   labelcanvas.drawLabel(label, shape, Styler.getAerodromeLabelStyle())
  // }

  return aerodromePainter
}

export function constructMeasureLabelContent (feature) {
  switch (feature.measureType) {
    case 'Regulation':
      return constructRegulationLabelContent(feature)
    case 'Rerouting':
      return constructReroutingLabelContent(feature)
    default:
      return ''
  }

}

export function constructRegulationLabelContent (feature) {
  const averageDelay = feature.properties.averageDelay
  const headerColor = Styler.getRegulationLabelColor(averageDelay)

  const labelColor = Styler.getRegulationLabelColor(0)

  const id = feature.properties.locationName ? feature.properties.locationName : undefined

  const label = getLabelForRegulationReason(feature.properties.reason)

  return editLabelContent(label, id, feature.properties.nrImpactedFlights, headerColor, labelColor)
}

function constructReroutingLabelContent (feature) {
  const labelColor = Styler.getRegulationLabelColor(0)

  const id = feature.properties.locationName ? feature.properties.locationName : undefined

  let label = getLabelForReroutingReason(feature.properties.reason)

  return editLabelContent(label, id, feature.properties.nrImpactedFlights, undefined, labelColor)
}

function editLabelContent (label, id, nrImpactedFlights, headerColor, labelColor) {
  let contents = ''

  if (id) {
    if (headerColor) {
      //construct the header
      contents += '<h3 class=\'header\'><span style=color:' + headerColor + ';>' + id + '</span></h3>'
      contents += '<hr class=\'luciad lcdBalloon hr\'/>'
    }

    //fill the label with id
    label = label.replace('{name}', id)
  } else {
    // organize the label without id
    label = label.replace('{name}', 'this area')
  }

  //fill the label with nrImpactedFlights
  label = label.replace('{nrImpactedFlights}', nrImpactedFlights)

  // construct the label html
  const splitLabel = label.split('\\n')
  splitLabel.forEach((line) => {

    contents += '<span style=color:' + labelColor + ';>' + line + '</span>'
    contents += '<br>'
  })

  return contents
}

export function makeDirectorPlanePainter (scale) {
  return makePlanePainter(scale, Styler.getDirectorAirplaneIcon)
}

export function makeDefaultPlanePainter (scale) {
  return makePlanePainter(scale, Styler.getAirplaneIcon)
}

function makePlanePainter (scale, airplaneIconFunction) {
  return new ParameterizedPointPainter({
    attributes: {
      isVisible: function (feature, shape) {
        return shape.hidden ? 0 : 1
      }
    },
    heading: (feature, shape) => shape.heading ? shape.heading : 0,
    regular: {
      //part of code commented that adjusts the icon size based on scale - for the moment DGCom prefers
      iconExpression: ExpressionFactory.cases(ExpressionFactory.icon(airplaneIconFunction(14)))
        .when(ExpressionFactory.gt(scale, ExpressionFactory.number(0)))
        .then(ExpressionFactory.icon(airplaneIconFunction(10)))
    },
    visibilityExpression: ExpressionFactory.gte(ExpressionFactory.attribute('isVisible'), ExpressionFactory.number(1))
  })
}

export function makeTrajectoryPainter (trajectoryColor) {
  const dataSetStartTime = moment().startOf('day').valueOf() // set to 12:00 am today
  const dataSetEndTime = moment().startOf('day').add(1, 'days').valueOf() // set to 12:00 am tomorrow

  return new TrajectoryPainter({
    defaultColor: trajectoryColor || 'rgba(255, 255,0,200)',
    lineWidth: 1,
    lineType: LineType.SHORTEST_DISTANCE,
    timeWindow: [dataSetStartTime, dataSetEndTime],
    timeProvider: function (feature, shape, pointIndex) {
      //convert to milliseconds
      return feature.properties.timestamps[pointIndex] * 1000
    },
    // outsideTimeRangeWeight: 0.004
  })
}