// @flow

import cfg from '../../config'
import type { VersionActions } from '../actions/version'
import { CHECK_VERSION } from '../actions/version'

export type VersionState = {
  compatible: boolean
}

const initial = {
  compatible: false
}

export default function version (state: VersionState = initial, action: VersionActions): VersionState {
  switch (action.type) {
    case CHECK_VERSION:
      return { compatible: _checkVersion(action.payload) }
    default:
      return state
  }
}

function _checkVersion (version) {
  return version.startsWith(cfg.apiMajorVersion)
}