// @flow

import * as React from 'react'
import './MapView.css'

type Props = {
  id: string
}

export default function MapView (props: Props) {
  return <div id={props.id} className='map'/>
}
