import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { KPIS_STATS } from '../../redux/actions/updater'
import connectKPIs from '../../redux/connectors/kpis'
import Canary from '../elements/Canary'
import { IndicatorKPI } from '../elements/IndicatorKPI'
import IconDelays from '../icons/icon-delays-cut'
import IconFlight from '../icons/icon-plane'
import './display-kpis.css'

const KPIs = {
  planned: {
    title: 'planned',
    icon: <IconFlight className='planned'/>,
    src: KPIS_STATS
  },
  airborne: {
    title: 'airborne',
    icon: <IconFlight className='airborne'/>,
    src: KPIS_STATS
  },
  landed: {
    title: 'landed',
    icon: <IconFlight className='landed'/>,
    src: KPIS_STATS
  },
  totalDelay: {
    title: 'delay',
    icon: <IconDelays/>,
    src: KPIS_STATS,
    units: '\''
  }
}

const DisplayKPIsView = ({ children }) =>
  <div className='display-kpis'>
    {children}
  </div>

class DisplayKPIsController extends React.Component {

  constructor (props) {
    super(props)

    this.kpis = props.kpis

    this.updater = props.updater
    this.kpis.map(ind => this.updater.addSubscription(KPIs[ind] && KPIs[ind].src))
    this.updater.start()
  }

  isLive () {
    const { values = {} } = this.props
    return this.kpis.reduce((r, ind) => r && values[ind] && values[ind].online, true)
  }

  renderIndicators () {
    const { values } = this.props
    return this.kpis.map(i => {
      const indicator = KPIs[i]
      if (!indicator) return null
      return <IndicatorKPI key={indicator.title}
                           icon={indicator.icon}
                           title={indicator.title}
                           value={values[i].value}
                           units={indicator.units}/>
    })
  }

  componentWillUnmount () {
    this.kpis.map(ind => this.updater.delSubscription(KPIs[ind] && KPIs[ind].src))
  }

  render () {
    return (
      <DisplayKPIsView>
        {this.renderIndicators()}

        <Canary key='Canary'
                alive={!this.props.compatible || this.isLive()}
                color={!this.props.compatible ? 'red' : 'green'}/>
      </DisplayKPIsView>
    )
  }
}

DisplayKPIsController.propTypes = {
  updater: PropTypes.object.isRequired,
  kpis: PropTypes.array.isRequired,
  values: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    compatible: state.version.compatible
  }
}

export default connect(mapStateToProps)(connectKPIs(DisplayKPIsController))
