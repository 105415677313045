const TimeUnit = {
  DAY: 'Day',
  HOUR: 'Hour',
  MINUTE: 'Minute',
  SECOND: 'Second'
}

Object.freeze(TimeUnit)

const TimeUnits =
  {
    Day: 86400000,//millisecondsPerDay
    Hour: 3600000,//millisecondsPerHour
    Minute: 60000,//millisecondsPerMinute
    Second: 1000,
    Millisecond: 1
  }

export function calculateTimelapseSpeedUp (animationDuration, dataDuration, animationDurationTimeUnit, dataDurationTimeUnit) {

  animationDurationTimeUnit = animationDurationTimeUnit ? animationDurationTimeUnit : TimeUnit.SECOND
  dataDurationTimeUnit = dataDurationTimeUnit ? dataDurationTimeUnit : TimeUnit.DAY

  const animationDurationInSeconds = convertTimeUnits(animationDurationTimeUnit, TimeUnit.SECOND, animationDuration)
  const dataDurationInSeconds = convertTimeUnits(dataDurationTimeUnit, TimeUnit.SECOND, dataDuration)

  return dataDurationInSeconds / animationDurationInSeconds

}

export function calculateTimelapseDuration (animationSpeedUp, dataDuration, dataDurationTimeUnit) {
  dataDurationTimeUnit = dataDurationTimeUnit ? dataDurationTimeUnit : TimeUnit.DAY
  const dataDurationInSeconds = convertTimeUnits(dataDurationTimeUnit, TimeUnit.SECOND, dataDuration)

  return dataDurationInSeconds / animationSpeedUp
}

export function convertTimeUnits (unitFrom, unitTo, amount) {
  return (TimeUnits[unitFrom] / TimeUnits[unitTo]) * amount
}