import * as React from 'react'
import './situation-table.css'
import { getLabel4Delay , getDelayValue } from '../../../../common/criterias'
import { formatValue } from '../../../../common/formats'
type Props = {
  title: string,
  headers: *,
  data: *
}

export default class SituationTable extends React.Component<Props> {

  render () {
    return <section className="situation-table show-off">
       <h5>{this.props.title}</h5>
       <table>
         <thead>
            <tr>
            {this.props.headers.map( (h,index)=> 
              <th key={index}>{h}</th>
              )}
            </tr>
         </thead>
         <tbody>
           {this.props.data.value.map((v,index)=> 
            <tr key={index}>
              <td>{v.title}</td>
              <td>{v.delay!==0? formatValue(v.delay)+ "'" : "0'"}</td>
              <td>
                <span className={getLabel4Delay(v.value)}>{v.value!==-1? getDelayValue(v.value) + "'" : "0'"}</span>
              </td>
            </tr>
           )}
         </tbody>
       </table>
    </section>
  }
}