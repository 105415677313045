import React from 'react'
import './demoscreen.css'

export const KPIDemoScreen = () =>
  <div className='kpi-demoscreen'>
    <div className='lefttop'>
      <iframe title='PA' src='/kpi/pa' frameBorder='0'></iframe>
    </div>
    <div className='leftbottom'>
      <iframe title='Top 5 live' src='/kpi/top5lv' frameBorder='0'></iframe>
    </div>
    <div className='righttop'>
      <iframe title='LD' src='/kpi/ld' frameBorder='0'></iframe>
    </div>
    <div className='rightbottom'>
      <iframe title='Top 5 accumulated' src='/kpi/top5ac' frameBorder='0'></iframe>
    </div>
  </div>


