// @flow

import * as ShapeFactory from '@luciad/ria/shape/ShapeFactory'
import * as TransformationFactory from '@luciad/ria/transformation/TransformationFactory'
import type { RemoveHandle } from '@luciad/ria/util'
import type { ParameterExpression } from '@luciad/ria/util/expression/Expression'
import * as ExpressionFactory from '@luciad/ria/util/expression/ExpressionFactory'
import type { FeatureLayer } from '@luciad/ria/view/WebGLMap'
import { WebGLMap } from '@luciad/ria/view/WebGLMap'
import PropTypes from 'prop-types'
import * as React from 'react'
import cfg from '../../../config'
import { COMMON_SPATIAL_REFERENCE, EUROPE_CONIC } from './bounds'
import {
  createBackgroundLayer,
  createDirectorDisplayNameLayer,
  createDirectorPlaneTrajectoryLayer,
  createLonLatGridLayer
} from './layers'
import MapView from './MapView'

type Props = {
  id: string
}

export class DirectorMapController extends React.Component<Props> {

  /**
   attributes
   */
  map: WebGLMap
  trajectoryLayer: FeatureLayer
  displayNameLayer: FeatureLayer
  displayNameReloadTimerID: *
  flightReloadTimerID: *
  paintingScale: ParameterExpression<number>
  lastKnownMapScale: number = 0
  mapChangeHandle: ?RemoveHandle = null
  rotationAndZoom = -1
  mapStateForLog: *

  constructor (props: Props) {
    super(props)

    let self: any = this
    self.schedulePlaneAnimation = this.schedulePlaneAnimation.bind(this)
  }

  componentDidMount () {
    this.initMap()
  }

  componentWillUnmount () {
    clearInterval(this.displayNameReloadTimerID)
    clearInterval(this.flightReloadTimerID)
    if (this.mapChangeHandle) this.mapChangeHandle.remove()
    this.map.destroy()
  }

  initMap () {
    this.paintingScale = ExpressionFactory.numberParameter(0)

    this.displayNameLayer = createDirectorDisplayNameLayer()
    this.trajectoryLayer = createDirectorPlaneTrajectoryLayer(this.paintingScale)

    this.map = new WebGLMap(this.props.id, { reference: EUROPE_CONIC })
    this.map.layerTree.addChild(createBackgroundLayer(this.props.background))
    this.map.layerTree.addChild(createLonLatGridLayer())
    this.map.layerTree.addChild(this.trajectoryLayer)
    this.map.layerTree.addChild(this.displayNameLayer, 'top')

    this.map.effects.atmosphere = false // to disable the blue sky

    this.mapChangeHandle = this.map.on('MapChange', function () {
      if (this.lastKnownMapScale !== this.map.mapScale[0]) {
        this.lastKnownMapScale = this.map.mapScale[0]
        this.paintingScale.value = this.lastKnownMapScale > 2e-7 ? 0 : 1
      }
    }, this)

    // Initialize timer to reload the flight data
    this.flightReloadTimerID = setInterval(() => {
      // $FlowFixMe
      this.trajectoryLayer.loadingStrategy.queryProvider.invalidate()
    }, cfg.flightReloadIntervalInMilliSeconds)

    this.fitMapOnScreen()
    this.schedulePlaneAnimation()
  }

  fitMapOnScreen () {
    const transformation = TransformationFactory.createTransformation(COMMON_SPATIAL_REFERENCE, this.map.reference)

    // 1. Define a map bounds including some predefined points
    const bounds = [
      [-18.2, 27.7], [-18.0, 27.5], [-15.6, 27.7], [-14.3, 28.0], [-14.0, 28.2],  // Canaries
      [46.1, 38.8], [46.5, 38.8], [46.6, 39.2], [46.6, 39.6],                     // Armenia
      [46.5, 41.8], [46.6, 41.1], [46.7, 41.3], [46.4, 41.9],                     // Georgia
      [23.5, 65.8]                                                                // North Europe
    ]
      .map(c => ShapeFactory.createPoint(COMMON_SPATIAL_REFERENCE, c))
      .map(p => transformation.transform(p))
      .map(p => p.bounds)
      .reduce((result, b, i) => {
        result.setTo2DUnion(b)
        return result
      })

    // 2. Define the margin using the top value of ticker component
    const margin = 84 // value of .headline-news.bottom + .headline-news.height

    // 3. Fit the map using the defined settings
    this.map.mapNavigator.fit({ bounds: bounds, fitMargin: margin + 'px', animate: false })
  }

  schedulePlaneAnimation () {
    this.trajectoryLayer.whenReady().then(() =>
      setTimeout(() => {
        this.trajectoryLayer.shapeProvider.invalidateAll()
        this.schedulePlaneAnimation()
      }, cfg.flightAnimationDelayInMilliSeconds)
    )
  }

  render () {
    return (<MapView id={this.props.id}/>)
  }
}

DirectorMapController.propTypes = {
  id: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
}
