// @flow

import * as ReferenceProvider from '@luciad/ria/reference/ReferenceProvider'
import * as ShapeFactory from '@luciad/ria/shape/ShapeFactory'
import type { Bounds } from 'luciad'

export const GLOBE_SPATIAL_REFERENCE = ReferenceProvider.getReference('EPSG:4978')
export const COMMON_SPATIAL_REFERENCE = ReferenceProvider.getReference('EPSG:4326')
export const WEB_MERCATOR_REFERENCE = ReferenceProvider.getReference('EPSG:3857')
export const CRS84_REFERENCE = ReferenceProvider.getReference('CRS:84')

function _getEuropeConicReference () {
  const wktString = 'PROJCS["Europe_Lambert_Conformal_Conic",GEOGCS["GCS_European_1950",DATUM["European_Datum_1950",SPHEROID["International_1924",6378388,297]],PRIMEM["Greenwich",0],UNIT["Degree",0.017453292519943295]],PROJECTION["Lambert_Conformal_Conic_2SP"],PARAMETER["False_Easting",0],PARAMETER["False_Northing",0],PARAMETER["Central_Meridian",10],PARAMETER["Standard_Parallel_1",43],PARAMETER["Standard_Parallel_2",62],PARAMETER["Latitude_Of_Origin",30],UNIT["Meter",1],AUTHORITY["EPSG","102014"]]'

  return ReferenceProvider.parseWellKnownText(wktString)
}

export const EUROPE_CONIC = _getEuropeConicReference()

export class DefaultView {
  constructor (bounds: Bounds, expand = true) {
    this.bounds = bounds
    this.expand = expand
  }

  closeTo (bounds: Bounds): boolean {
    const coordinates = this.expand ?
      [this.bounds.x - 1, this.bounds.width + 2, this.bounds.y - 1, this.bounds.height + 2] :
      [this.bounds.x, this.bounds.width, this.bounds.y, this.bounds.height]
    const intersect = ShapeFactory.createBounds(COMMON_SPATIAL_REFERENCE, coordinates)
    intersect.setTo2DIntersection(bounds)
    return intersect.width > 0 || intersect.height > 0
  }

  bounds: Bounds
  expand: boolean
}

export const boundsEnum = Object.freeze({
  NMOC: 0,   // EUROPE
  EGLL: 1,   // Heathrow
  LFPG: 2,   // Charles de Gaulle
  EDDF: 3,   // Frankfurt
  EHAM: 4,   // Amsterdam/Schiphol
  LEMD: 5    // Barajas/Madrid
})

const bounds: Array<DefaultView> = [
  new DefaultView(ShapeFactory.createBounds(COMMON_SPATIAL_REFERENCE, [-5, 62.2 / 2, 39, 36.2 / 2.4]), false),
  new DefaultView(ShapeFactory.createPoint(COMMON_SPATIAL_REFERENCE, [-0.461941, 51.4706]).bounds),
  new DefaultView(ShapeFactory.createPoint(COMMON_SPATIAL_REFERENCE, [2.571, 49.004]).bounds),
  new DefaultView(ShapeFactory.createPoint(COMMON_SPATIAL_REFERENCE, [8.5707, 50.0484]).bounds),
  new DefaultView(ShapeFactory.createPoint(COMMON_SPATIAL_REFERENCE, [4.77511, 52.3122]).bounds),
  new DefaultView(ShapeFactory.createPoint(COMMON_SPATIAL_REFERENCE, [-3.56769, 40.4684]).bounds)
]

export const EUROCONTROL_BOUNDS = new DefaultView(ShapeFactory.createBounds(COMMON_SPATIAL_REFERENCE, [-13, 60, 34, 30]), false)

export default bounds
