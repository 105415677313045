import numeral from 'numeral'

/**
 * Formats value for indicators
 * @param value
 * @returns {*}
 */
export function formatValue (value) {
  let res = parseInt(value, 10)
  res = isNaN(res) ? 0 : res
  return numeral(res).format('0,0')
}
