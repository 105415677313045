import React from 'react'
import './icon.css'

const icons ={
    'total' : { src: '/images/sigma.svg', width:'27px', height:'32px'} ,
    'delay' : { src: '/images/clock.svg', width:'32px', height:'32px'} ,
    'planned' : { src: '/images/plane-departure.svg', width:'36px', height:'26px'} ,
    'airborne' : { src: '/images/plane-icon.svg', width:'30px', height:'26px'} ,
    'landed' : { src: '/images/plane-arrival.svg', width:'33px', height:'26px'}
}

const Icon = ({id}) => 
    <img alt='icon' 
        className={'icon ' + id} 
        src={icons[id].src} 
        width={icons[id].width} 
        height={icons[id].height} />

export default Icon


