import React, { Component } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'
import { getClientId } from './common/serverlog'
import { BackgroundType as BT } from './components/elements/map'
import { AppDemoScreen } from './components/screens/AppDemo'
import DirectorMap from './components/screens/director/average-delay'
import DisplayKPIs from './components/screens/DisplayKPIs'
import DisplayMap from './components/screens/DisplayMap'
import DisplayTable from './components/screens/DisplayTable'
import { KPIDemoScreen } from './components/screens/KPIDemo'
import UploadFile from './components/screens/UploadFile'
import cfg from './config'
import { dispatchCheckVersion } from './redux/actions/version'
import rootReducer from './redux/reducers'
import { loadVersion } from './services/api'
import Updater from './services/data-provider'
import './styles/App.css'

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunkMiddleware)))

export const updater = new Updater(store, cfg.apiPollingInterval)

getClientId()

loadVersion().then(v => dispatchCheckVersion(v)(store.dispatch))

class App extends Component {

  render () {
    /* need to move the init logic out of DisplayMap */
    return (
      <Provider store={store}>
        <Router>
          <div className='App'>
            <Switch>
              <Route exact path="/all" component={AppDemoScreen}/>
              <Route exact path="/map"
                     render={props => <DisplayMap updater={updater}/>}/>
              <Route exact path="/timelapse"
                     render={props => <DisplayMap updater={updater} trajectoryType='TimelapsePredictPainter'/>}/>
              <Route exact path="/timelapse/live"
                     render={props => <DisplayMap updater={updater} trajectoryType='TimelapseLivePainter'/>}/>
              <Route exact path="/timelapse/pred"
                     render={props => <DisplayMap updater={updater} trajectoryType='TimelapsePredictPainter'/>}/>
              <Route exact path="/kpi/pa"
                     render={props => <DisplayKPIs updater={updater} {...props} kpis={['planned', 'airborne']}/>}/>
              <Route exact path="/kpi/ld"
                     render={props => <DisplayKPIs updater={updater} {...props} kpis={['landed', 'totalDelay']}/>}/>
              <Route exact path="/kpi/top5ap"
                     render={props => <DisplayTable updater={updater} {...props} kpi='accumulatedDelays'/>}/>
              <Route exact path="/kpi/top5as"
                     render={props => <DisplayTable updater={updater} {...props} kpi='liveDelays'/>}/>
              <Route exact path="/kpi/top5ac"
                     render={props => <DisplayTable updater={updater} {...props} kpi='accumulatedDelays'/>}/>
              <Route exact path="/kpi/top5lv"
                     render={props => <DisplayTable updater={updater} {...props} kpi='liveDelays'/>}/>
              <Route exact path="/kpi" component={KPIDemoScreen}/>
              <Route exact path="/uploadScenarioFile" component={UploadFile}/>
              <Route exact path="/director/:background?"
                     render={props => <DirectorMap updater={updater} {...props}
                                                   background={_convertBackground(props)}/>}/>
              <Route exact path="/"
                     render={props => <DisplayMap updater={updater}/>}/>
              <Route render={() => <Redirect to="/"/>}/>
            </Switch>
          </div>
        </Router>
      </Provider>
    )
  }

}

function _convertBackground (props) {
  const background = props.match.params.background || ''
  switch (background) {
    case '':
    case 'basic':
      return BT.MT_BASIC
    case 'satellite':
      return BT.MT_SATELLITE
    default:
      throw new Error('Unsupported background type ' + background)
  }
}

export default App
