import React from 'react'
import './demoscreen.css'

export const AppDemoScreen = () =>
  <div className='app-demoscreen'>
    <div className='lefttop'>
      <iframe title='PA' src={'/kpi/pa'} frameBorder='0'></iframe>
    </div>
    <div className='leftbottom'>
      <iframe title='Top 5 apts' src='/kpi/top5ap' frameBorder='0'></iframe>
    </div>
    <div className='center'>
      <iframe title='Map' src="/map" frameBorder='0'></iframe>
    </div>
    <div className='righttop'>
      <iframe title='LD' src={'/kpi/ld'} frameBorder='0'></iframe>
    </div>
    <div className='rightbottom'>
      <iframe title='Top 5 atcs' src='/kpi/top5as' frameBorder='0'></iframe>
    </div>
  </div>
