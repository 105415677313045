import React from 'react'
import { formatValue } from '../../common/formats'
import './indicators.css'

export const IndicatorKPI = ({value, icon, title = 'Indicator title', units = ''}) => (
  <div className='indicator-kpi'>
    <div className="icon">
      {icon}
    </div>
    <div className="value">
      {formatValue(value)}{units}
    </div>
    <div className="title">
      {title}
    </div>
  </div>
)
