// @flow

import type { Dispatch } from 'redux'

export const CHECK_VERSION = 'CHECK_VERSION'

export type VersionPayload = string

type CheckVersion = {
  type: CHECK_VERSION,
  payload: VersionPayload
}

export type VersionActions = CheckVersion

export function dispatchCheckVersion (payload: VersionPayload) {
  return (dispatch: Dispatch) => dispatch({ type: CHECK_VERSION, payload })
}
