import * as ReferenceProvider from '@luciad/ria/reference/ReferenceProvider'
import * as ShapeFactory from '@luciad/ria/shape/ShapeFactory'
import * as TransformationFactory from '@luciad/ria/transformation/TransformationFactory'

const animationParametersList = [{
  eyePoint: [3990837.6503421324, -165844.2668007242, 5032664.9752695905],//atlantic
  pitch: 6.563752584970341,
  roll: 0,
  yaw: 276.59101166802986
}, {
  eyePoint: [3601968.1099741813, -1101784.4603288, 5217556.253096678]//pan to point in atlantic
}, {
  eyePoint: [6220382.399754441, -694863.4879733671, 4587668.385928034],//europe
  pitch: -62.978530492869254,
  roll: -0.30613343730010534,
  yaw: 16.28220182943972

}, {
  eyePoint: [4512361.7057733994, 828602.846831791, 4587668.385928034] // pan to middle europe
}]

const reference = ReferenceProvider.getReference('EPSG:4978')

export const animationList = [
  [
    { function: firstAnimationAtlantic, duration: 6000 },
    { function: secondAnimationAtlantic, duration: 6000 }
  ],
  [
    { function: firstAnimationEurope, duration: 3000 },
    { function: secondAnimationEurope, duration: 3000 },
    { function: thirdAnimationEurope, duration: 2500 },
    { function: fourthAnimationEurope, duration: 3000 }
  ]
]

function firstAnimationAtlantic (map) {
  return map.mapNavigator.lookFrom(
    ShapeFactory.createPoint(reference, animationParametersList[0].eyePoint),
    animationParametersList[0].yaw,
    animationParametersList[0].pitch,
    animationParametersList[0].roll,
    { animate: { duration: 10000 } })
}

function secondAnimationAtlantic (map) {
  return map.mapNavigator.pan({
    targetLocation: ShapeFactory.createPoint(reference,
      animationParametersList[1].eyePoint),
    animate: { duration: 3500 }
  })
}

function firstAnimationEurope (map) {
  return map.mapNavigator.lookFrom(
    ShapeFactory.createPoint(reference, animationParametersList[2].eyePoint),
    animationParametersList[2].yaw,
    animationParametersList[2].pitch,
    animationParametersList[2].roll,
    { animate: { duration: 5000 } })
}

function secondAnimationEurope (map) {
  return map.mapNavigator.pan({
    targetLocation: ShapeFactory.createPoint(reference,
      animationParametersList[3].eyePoint),
    animate: { duration: 2500 }
  })
}

function thirdAnimationEurope (map) {
  return map.mapNavigator.rotate({
    center: ShapeFactory.createPoint(reference,
      animationParametersList[3].eyePoint),
    deltaYaw: -150,
    deltaPitch: 30,
    animate: { duration: 7000 }
  })
}

function fourthAnimationEurope (map) {
  return map.mapNavigator.zoom({
    location: ShapeFactory.createPoint(reference,
      animationParametersList[3].eyePoint),
    factor: 3,
    animate: { duration: 2000 }
  })
}

export function getWorldFitBounds (bounds) {
  const transformation = TransformationFactory.createTransformation(bounds.reference, reference)
  const transformedBounds = transformation.transformBounds(bounds)
  return _justifyBounds(transformedBounds)
}

function _justifyBounds (bounds) {

  let boundsCopy

  if (bounds.width < 1e-5 && bounds.height < 1e-5) {
    boundsCopy = bounds.copy()
    const width = 1e5
    const x = boundsCopy.x - width / 2
    const y = boundsCopy.y - width / 2
    boundsCopy.setTo2D(x, width, y, width)
  } else {
    boundsCopy = bounds
  }

  return boundsCopy
}