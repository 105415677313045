// @flow

import type { RemoveHandle } from '@luciad/ria/util'
import type { ParameterExpression } from '@luciad/ria/util/expression/Expression'
import * as ExpressionFactory from '@luciad/ria/util/expression/ExpressionFactory'
import type { FeatureLayer } from '@luciad/ria/view/WebGLMap'
import { WebGLMap } from '@luciad/ria/view/WebGLMap'
import PropTypes from 'prop-types'
import * as React from 'react'
import { LogType, serverLog } from '../../../common/serverlog'
import cfg from '../../../config'
import bounds, { boundsEnum, GLOBE_SPATIAL_REFERENCE } from './bounds'
import { BackgroundType } from './index'
import {
  createBackgroundLayer,
  createDefaultDisplayNameLayer,
  createDefaultPlaneTrajectoryLayer,
  createLonLatGridLayer,
  panMap
} from './layers'
import MapView from './MapView'
import { getWorldFitBounds } from './timelapselive/TimelapseAnimationUtil'

const moment = require('moment-timezone')

type Props = {
  id: string
}

export class PlaneMapController extends React.Component<Props> {

  /**
   attributes
   */
  map: WebGLMap
  trajectoryLayer: FeatureLayer
  displayNameLayer: FeatureLayer
  displayNameReloadTimerID: *
  flightReloadTimerID: *
  timeLabelTimerID: *
  paintingScale: ParameterExpression<number>
  lastKnownMapScale: number = 0
  mapChangeHandle: ?RemoveHandle = null
  views = bounds.map(current => current.bounds)
  current = -1
  rotationAndZoom = -1
  mapStateForLog: *

  constructor (props: Props) {
    super(props)

    let self: any = this
    self.doMapAnimation = this.doMapAnimation.bind(this)
    self.schedulePlaneAnimation = this.schedulePlaneAnimation.bind(this)
  }

  componentDidMount () {
    this.initMap()
  }

  componentWillUnmount () {
    clearInterval(this.displayNameReloadTimerID)
    clearInterval(this.flightReloadTimerID)
    clearInterval(this.timeLabelTimerID)
    if (this.mapChangeHandle) this.mapChangeHandle.remove()
    this.map.destroy()
  }

  initMap () {
    this.paintingScale = ExpressionFactory.numberParameter(0)

    this.displayNameLayer = createDefaultDisplayNameLayer()
    this.trajectoryLayer = createDefaultPlaneTrajectoryLayer(this.paintingScale)

    this.map = new WebGLMap(this.props.id, { reference: GLOBE_SPATIAL_REFERENCE })
    this.map.layerTree.addChild(createBackgroundLayer(BackgroundType.AG_BLACK))
    this.map.layerTree.addChild(createLonLatGridLayer())
    this.map.layerTree.addChild(this.trajectoryLayer)
    this.map.layerTree.addChild(this.displayNameLayer, 'top')

    this.map.effects.atmosphere = false // to disable the blue sky

    //to display the time label  at the bottom
    this.createTimeDOMNode()

    //To display the prediction indication label at the top left
    this.createTrafficDataDOMNode()

    // Initialize timer to display the current time
    this.timeLabelTimerID = setInterval(() => {
      this.setTimeLabel(Date.now() / 1000)
    }, cfg.timeLabelUpdateIntervalInMilliSeconds)

    this.displayNameReloadTimerID = setInterval(() => {
      // $FlowFixMe
      this.displayNameLayer.loadingStrategy.queryProvider.invalidate()
      this.refreshViews()
    }, cfg.displayNameReloadIntervalInMilliSeconds)

    this.mapChangeHandle = this.map.on('MapChange', function () {
      if (this.lastKnownMapScale !== this.map.mapScale[0]) {
        this.lastKnownMapScale = this.map.mapScale[0]
        this.paintingScale.value = this.lastKnownMapScale > 2e-7 ? 0 : 1
      }
    }, this)

    // Initialize timer to reload the flight data
    this.flightReloadTimerID = setInterval(() => {
      // $FlowFixMe
      this.trajectoryLayer.loadingStrategy.queryProvider.invalidate()
    }, cfg.flightReloadIntervalInMilliSeconds)

    this.refreshViews()
    this.doMapAnimation()
    this.schedulePlaneAnimation()
  }

  doMapAnimation () {
    this.rotationAndZoom++
    let fitOnNMOC = this.rotationAndZoom % 2 === 0

    let promise

    if (fitOnNMOC) {
      //fit on the NMOC area
      if (this.rotationAndZoom === 0) {
        //this is the very first run of this method
        //fit the map on the NMOC area
        //save the state when it is fitted on the NMOC area for later runs
        promise = this.fitOnNMOCArea()
      } else {
        //in all the consecutive runs, fit on the saved state of NMOC
        promise = this.map.restoreState(this.nmocMapState, { animate: true })
      }

      //if fit on the NMOC area, zoom in by a factor of 1.63
      promise = this.zoomByFactor(1.63, promise)

    } else {
      //fit on one of the items in the views array
      this.current++

      // views array could have been changed since the setTimeout(), restart at 0 if needed
      if (this.current >= this.views.length) {
        serverLog('animation', LogType.DEBUG, 'doMapAnimation start from beginning',
          {
            viewsLength: this.views.length,
            current: this.current,
          })
        this.current = 0
      }

      const boundsToFit = getWorldFitBounds(this.views[this.current])
      promise = this.map.mapNavigator.fit({ bounds: boundsToFit, animate: true })
    }

    promise.then(() => {
      const deltaYaw = fitOnNMOC ? -5 : 5
      this.mapStateForLog = this.map.saveState()
      serverLog('animation', LogType.DEBUG, 'Map camera values after fit ',
        {
          rotationAndZoom: this.rotationAndZoom,
          fitOnNMOC: fitOnNMOC,
          current: this.current,
          deltaYaw: deltaYaw,
          eyePointX: this.mapStateForLog.transformation3D.eyePointX,
          eyePointY: this.mapStateForLog.transformation3D.eyePointY,
          eyePointZ: this.mapStateForLog.transformation3D.eyePointZ,
          pitch: this.mapStateForLog.transformation3D.pitch,
          roll: this.mapStateForLog.transformation3D.roll,
          yaw: this.mapStateForLog.transformation3D.yaw
        })
      this.map.mapNavigator.rotate({
        deltaYaw: deltaYaw,
        animate: true
      }).catch(function (err) { serverLog('animation', LogType.ERROR, 'doMapAnimation ERROR when rotating 1', { error: err })})
    }).catch(function (err) {serverLog('animation', LogType.ERROR, 'doMapAnimation ERROR when rotating 2', { error: err })}).then(() => { setTimeout(this.doMapAnimation, cfg.mapAnimation[fitOnNMOC ? 0 : 1]) })
  }

  refreshViews () {
    // merge the default views with the regulated areas, the result is the array of views towards which will animate
    // we don't want to animate to one of the default views if a display name location is nearby
    // since the default views are the main aerodromes, it stands to reason that the nearby area is often regulated
    this.displayNameLayer._model.query().then(cursor => {
      function defaultViewsToKeepGiven (displayName) {
        return bounds.map((current, i) => !current.closeTo(displayName._shape.bounds) && keepers[i])
      }

      let keepers = new Array(bounds.length).fill(true)
      let newViews = []
      while (cursor.hasNext()) {
        let displayName = cursor.next()
        keepers = defaultViewsToKeepGiven(displayName)
        // zoom in to view
        newViews.push(displayName._shape.bounds)
      }
      // merge the default views, if there is not a regulated area nearby, always merge the entire NMOC view
      for (let i = keepers.length; i > 0; i--) {
        if (keepers[i]) {
          newViews.unshift(bounds[i].bounds)
        }
      }

      newViews.forEach((item, index) => serverLog('animation', LogType.DEBUG, 'refreshViews ' + index,
        {
          xFocusPoint: item.xFocusPoint,
          yFocusPoint: item.yFocusPoint,
          coordinates: item.coordinates
        }))
      this.views = newViews
    })
  }

  fitOnNMOCArea () {
    const nmocBounds = getWorldFitBounds(bounds[boundsEnum.NMOC].bounds)
    let promise = this.map.mapNavigator.fit({ bounds: nmocBounds, animate: false })
    //and also in the initial run pan the map to 50, 30
    return promise.then(() => {
      this.nmocMapState = this.map.saveState()
      panMap(this.map, 50, 30, true)
    }).catch(function (err) {
      serverLog('animation', LogType.ERROR, 'doMapAnimation ERROR when panning', { error: err })
    })
  }

  zoomByFactor (factor, promise) {
    return promise.then(() => this.map.mapNavigator.zoom({
      factor: factor,
      animate: true
    })).catch(function (err) {
      serverLog('animation', LogType.ERROR, 'doMapAnimation ERROR when zooming', { error: err })
    })
  }

  schedulePlaneAnimation () {
    this.trajectoryLayer.whenReady().then(() =>
      setTimeout(() => {
        this.trajectoryLayer.shapeProvider.invalidateAll()
        this.schedulePlaneAnimation()
      }, cfg.flightAnimationDelayInMilliSeconds)
    )
  }

  /**
   * Creates the DOM node to display the clock on the map
   */
  createTimeDOMNode () {
    let timeLabel = document.createElement('div')
    timeLabel.setAttribute('id', 'onMapTimeLabel')
    timeLabel.innerHTML = '<span id="livetimelabel" class="upLeft4 noselect" ></span> '
    this.map.domNode.appendChild(timeLabel)
  }

  /**
   * Creates the DOM node to display the clock on the map
   */
  createTrafficDataDOMNode () {
    let liveTrafficLabel = document.createElement('div')
    liveTrafficLabel.setAttribute('id', 'onMapTrafficLabel')
    liveTrafficLabel.innerHTML = '<span id="livetrafficlabel" class="upLeft noselect" >Live traffic</span>'
    this.map.domNode.appendChild(liveTrafficLabel)
  }

  setTimeLabel (currentTime) {
    document.getElementById('livetimelabel').innerHTML = moment.unix(currentTime).format('HH:mm') + ' CET'
  }

  render () {
    return (<MapView id={this.props.id}/>)
  }
}

PlaneMapController.propTypes = {
  id: PropTypes.string.isRequired,
}

