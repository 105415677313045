import React from 'react'
import PropTypes from 'prop-types'
import FlipMove from 'react-flip-move'
import { formatValue } from '../../common/formats'
import {
  getLabel4Delay,
  getDelayValue
} from '../../common/criterias'
import './indicators.css'

const TableRowDelaysView = ({id, type, value, labelClass = ''}) =>
  <div className='row-delays'>
    <div className='id'>
      {type}
    </div>
    <div className='type'>
      {id}
    </div>
    <div className={`value ${labelClass}`}>
      {value}
    </div>
  </div>

const TableRowView = ({id, delay, value, labelClass = ''}) =>
  <div className='row-delays'>
    <div className='id'>
      {id}
    </div>
    <div className='delay'>
      {formatValue(delay)}'
    </div>
    <div className={`value ${labelClass}`}>
      <span>{value}'</span>
    </div>
  </div>

export class TableRowDelays extends React.Component {
  render () {
    return <TableRowDelaysView {...this.props} />
  }
}

export class TableRowLiveDelays extends React.Component {
  render () {
    const {value} = this.props
    const props = {
      ...this.props,
      labelClass: getLabel4Delay(value),
      value: getDelayValue(value)
    }
    return <TableRowDelaysView {...props}  />
  }
}

TableRowLiveDelays.propTypes = {
  value: PropTypes.any.isRequired
}

export class TableRow extends React.Component {
  render () {
    const {value} = this.props
    const props = {
      ...this.props,
      labelClass: getLabel4Delay(value),
      value: getDelayValue(value)
    }
    return <TableRowView {...props}  />
  }
}

TableRow.propTypes = {
  value: PropTypes.any.isRequired
}

export const TableIndicator = ({children, title, header, isLoading = false, className = 'indicator table'}) => (
  <div className={className}>
    {!header ? <div><span>{title}</span></div> : header}
    {!isLoading &&
    <FlipMove duration={750} easing='ease-out'>
      {children}
    </FlipMove>
    }
    {isLoading &&
    <div>
      <span>Loading data...</span>
    </div>
    }
  </div>
)
