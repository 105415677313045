import * as GeodesyFactory from '@luciad/ria/geodesy/GeodesyFactory'
import { GeoJsonCodec } from '@luciad/ria/model/codec/GeoJsonCodec'
import * as ShapeFactory from '@luciad/ria/shape/ShapeFactory'
import cfg from '../../../../config'
import { COMMON_SPATIAL_REFERENCE, GLOBE_SPATIAL_REFERENCE } from '../bounds'
import TimelapseWorker from './timelapselive.worker'

const timelapseWorker = new TimelapseWorker()
export const geoJsonCodec = new GeoJsonCodec()

//new timelapse
let timelapsePeriod
let timelapseData = []//an array of arrays containing the flight information as well as the related measure feature
let timelapseRemaningFlights = [] //[flightFeature]
const shapeMap = new Map()
let isDataReady = false
const geodesy = GeodesyFactory.createCartesianGeodesy(GLOBE_SPATIAL_REFERENCE)

timelapseWorker.onmessage = function (e) {
  //decode the data
  const decodedData = geoJsonCodec.decode({
    content: e.data.featureCollection,
    contentType: 'application/json;charset=utf-8'
  })

  if (e.data.type === 'fetchTimelapse.success') {
    timelapseData = []
    shapeMap.clear()
    while (decodedData.hasNext()) {
      const timelapse = decodedData.next()
      if (timelapse.properties.type === 'PERIOD') {
        timelapsePeriod = {
          start: timelapse.properties.timelapseStart,
          end: timelapse.properties.timelapseEnd
        }
      } else {
        const decodedFlights = geoJsonCodec.decode({
          content: timelapse.properties.flights,
          contentType: 'application/json;charset=utf-8'
        })
        const flights = []
        while (decodedFlights.hasNext()) {
          const flight = decodedFlights.next()
          flights.push(flight)
        }

        if (timelapse.properties.type === 'REROUTING' || timelapse.properties.type === 'REGULATION') {
          const aerodromes = []
          const arrivingAerodromesShapes = []
          const departingAerodromesShapes = []
          const shapesOfTimeLapse = [departingAerodromesShapes, arrivingAerodromesShapes]

          if (timelapse.properties.type === 'REROUTING') {
            const decodedAerodromes = geoJsonCodec.decode({
              content: timelapse.properties.flowElements,
              contentType: 'application/json;charset=utf-8'
            })
            while (decodedAerodromes.hasNext()) {
              const aerodrome = decodedAerodromes.next()
              aerodromes.push(aerodrome)
              switch (aerodrome.properties.elementType) {
                case 'ARRIVING':
                  arrivingAerodromesShapes.push(aerodrome.shape)
                  break
                case 'DEPARTING':
                  departingAerodromesShapes.push(aerodrome.shape)
                  break
                default:
                  break
              }
            }
          }

          const decodedMeasures = geoJsonCodec.decode({
            content: timelapse.properties.referencedMeasure,
            contentType: 'application/json;charset=utf-8'
          })
          const decodedMeasure = decodedMeasures.hasNext() ? decodedMeasures.next() : undefined
          if (decodedMeasure) {
            shapesOfTimeLapse.push(decodedMeasure.shape)
          }

          timelapseData.push({
            id: timelapse.id,
            type: timelapse.properties.type,
            flights: flights,
            aerodromes: aerodromes,
            measureFeature: decodedMeasure
          })

          shapeMap.set(timelapse.id, shapesOfTimeLapse)
        } else if (timelapse.properties.type === 'REMAINING') {
          timelapseRemaningFlights = flights
        }
      }
    }
  }
}

export function updateTimelapseData () {
  isDataReady = false
  const dataReady = prepareData()
  dataReady.then(() => {
    isDataReady = true
  })

}

function prepareData () {
  //prepare timelapse data
  _fetchTimelapse()

  return new Promise(resolve => {
    function checkDataCompleted () {
      if (timelapsePeriod && timelapseRemaningFlights.length > 0) {
        //resolve the promise only after all the required data is gathered
        resolve()
      } else {
        //wait for the data to get fetched by the web worker and is ready
        setTimeout(checkDataCompleted, 1000)
      }
    }

    checkDataCompleted()
  })
}

export function getTimelapseData () {
  return timelapseData
}

export function getTimelapsePeriod () {
  return timelapsePeriod
}

export function getRemainingFlights () {
  return timelapseRemaningFlights
}

export function anyExistingTimelapse () {
  return timelapseData.length > 0 ? true : false
}

export function numberOfTimelapse () {
  return timelapseData.length
}

export function getShapeList (reroutingId) {
  return ShapeFactory.createShapeList(COMMON_SPATIAL_REFERENCE, Array.prototype.concat.apply([], shapeMap.get(reroutingId)))
}

export function getDepartingAerodromesShapeList (reroutingId) {
  return ShapeFactory.createShapeList(COMMON_SPATIAL_REFERENCE, shapeMap.get(reroutingId)[0])
}

export function getAllAerodromes () {
  const aerodromes = []
  timelapseData.forEach(td => aerodromes.push.apply(aerodromes, td.aerodromes))
  return aerodromes
}

export function getAerodromesShapeListToZoom (reroutingId) {
  let shapeList
  const measureShape = getMeasureShape(reroutingId)
  if (isArrivingDeparting(reroutingId) && measureShape) {
    const distance1 = geodesy.distance3D(getDepartingAerodromesShapeList(reroutingId).focusPoint, measureShape.bounds.focusPoint)
    const distance2 = geodesy.distance3D(getArivingAerodromesShapeList(reroutingId).focusPoint, measureShape.bounds.focusPoint)

    if (distance1 < distance2) {
      shapeList = getDepartingAerodromesShapeList(reroutingId)
    } else {
      shapeList = getArivingAerodromesShapeList(reroutingId)
    }
  } else {
    shapeList = getDepartingAerodromesShapeList(reroutingId)
    if (shapeList.shapeCount === 0) {
      shapeList = getArivingAerodromesShapeList(reroutingId)
    }
  }

  return shapeList
}

export function isArrivingDeparting (reroutingId) {
  return shapeMap.get(reroutingId)[0].length > 0 && shapeMap.get(reroutingId)[1].length > 0
}

export function getArivingAerodromesShapeList (reroutingId) {
  return ShapeFactory.createShapeList(COMMON_SPATIAL_REFERENCE, shapeMap.get(reroutingId)[1])
}

function getMeasureShape (reroutingId) {
  return shapeMap.get(reroutingId)[2]
}

export function isTimelapseDataReady () {
  return isDataReady
}

function _fetchTimelapse () {
  timelapseWorker.postMessage({ type: 'fetchTimelapse', url: cfg.urls.timelapseScenario })
}