// @flow
import type { Dispatch } from 'redux'

export type TickerPayload = {
  bannerText: string
}

type UpdateTicker = {
  type: 'UPDATE_TICKER',
  payload: TickerPayload
}

export type TickerActions = UpdateTicker

export function dispatchTickerUpdate (payload: TickerPayload) {
  return function (dispatch: Dispatch) {
    dispatch( {type: 'UPDATE_TICKER', payload} )
  }
}
