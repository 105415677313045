// @flow

import * as ReferenceProvider from '@luciad/ria/reference/ReferenceProvider'
import * as ShapeFactory from '@luciad/ria/shape/ShapeFactory'
import { ShapeType } from '@luciad/ria/shape/ShapeType'
import { WebGLMap } from '@luciad/ria/view/WebGLMap'
import PropTypes from 'prop-types'
import * as React from 'react'
import bounds, { boundsEnum, GLOBE_SPATIAL_REFERENCE } from '../bounds'
import { BackgroundType } from '../index'
import {
  createBackgroundLayer,
  createLonLatGridLayer,
  createTimelapseRegulationLayer,
  createTimelapseTrajectoryLayer
} from '../layers'
import MapView from '../MapView'
import { constructRegulationLabelContent, subscribeToMeasureEvents, unsubscribeFromMeasureEvents } from '../painters'
import { calculateTimelapseSpeedUp } from '../TimeUtils'
import { animationList, getWorldFitBounds } from './TimelapsePredictAnimationUtil'
import * as TimelapseDataProvider from './TimelapsePredictDataProvider'
import { getLoadingLabel, getRandomInt } from './TimelapsePredictLabelUtil'

type Props = {
  id: string
}

const moment = require('moment-timezone')

export class TimelapsePredictMapController extends React.Component<Props> {

  /**
   attributes
   */
  map: WebGLMap
  regulationLayer: FeatureLayer
  trajectoryLayers: [FeatureLayer] = []
  animationTimerID: *
  mapState: Object
  mapState2: Object
  lasttime: number
  //to break the animation frame loop when the data time frame is over for a trajectory layer.
  animationFrameId: number
  lengthOfTail: number = 125000
  reference = ReferenceProvider.getReference('EPSG:4978')

  //attributes for regulation animations
  colors = ['rgb(255, 120,16)', 'rgb(82, 116, 255)', 'rgb(82, 255, 56)']
  speedupRegulatedFlights: number = 2000
  animatingRegulations: boolean

  //attributes for 24h traffic animation
  mostSignificantRegulations = []
  remainingFlightsKey = 'RemainingFlights'
  remainingFlightLoadTimerID: *
  //calculate the speed up value to timelapse 24h of data in 30 seconds
  speedupRemaningFlights: number = calculateTimelapseSpeedUp(30, 1)

  constructor (props: Props) {
    super(props)

    let self: any = this
    self.runRegulatedFlightsTimeLapse = this.runRegulatedFlightsTimeLapse.bind(this)
  }

  componentDidMount () {
    this.initMap()
  }

  componentWillUnmount () {
    clearInterval(this.remainingFlightLoadTimerID)
    clearInterval(this.animationTimerID)
    unsubscribeFromMeasureEvents(this)
    this.map.destroy()
  }

  initMap () {
    //prepare the data
    const dataReady = TimelapseDataProvider.prepareData()

    //create the map and add the background and grid layers
    this.map = new WebGLMap(this.props.id, { reference: GLOBE_SPATIAL_REFERENCE })
    this.map.layerTree.addChild(createBackgroundLayer(BackgroundType.AG_WORLD))
    this.map.layerTree.addChild(createLonLatGridLayer())

    //create and add the trajectory layers for regulations
    for (let i = 0; i < 3; i++) {
      this.trajectoryLayers[i] = createTimelapseTrajectoryLayer(i + 1, this.colors[i])
      this.map.layerTree.addChild(this.trajectoryLayers[i].layer)
    }

    //create and add the trajectory layers for remaining flights
    this.trajectoryLayers[4] = createTimelapseTrajectoryLayer(4)
    this.map.layerTree.addChild(this.trajectoryLayers[4].layer, 'below', this.trajectoryLayers[0].layer)

    //create and add the regulation layer
    this.regulationLayer = createTimelapseRegulationLayer()
    this.map.layerTree.addChild(this.regulationLayer.layer, 'top')

    this.map.effects.atmosphere = false // to disable the blue sky

    //to display the time label  at the bottom
    this.createTimeDOMNode()

    //To display the prediction indication label at the top left
    this.createPredictedDataDOMNode()

    //add the loading panel to the dom
    this.createLoadingWindowDOMNode()

    subscribeToMeasureEvents(this)

    //save the state when it is zoomed in on the NMOC area
    const nmocBounds = getWorldFitBounds(bounds[boundsEnum.NMOC].bounds)
    this.map.mapNavigator.fit({ bounds: nmocBounds, animate: false })
    this.mapState = this.map.saveState()

    //save another state
    this.map.mapNavigator.lookFrom(
      ShapeFactory.createPoint(this.reference, [6052344.52559638, 1092153.6215620372, 4224465.501184974]),
      333.9450291492122,
      -35.058595215800377,
      0)
    this.mapState2 = this.map.saveState()
    dataReady.then((regulations) => {
      this.mostSignificantRegulations = regulations.filter(id => id !== this.remainingFlightsKey)
      this.startTimelapse()
    })

    this.map.restoreState(this.mapState, { animate: true })
  }

  clearLayers () {
    this.trajectoryLayers.forEach((layer) => {
        layer.store.clear()
      }
    )

    this.regulationLayer.store.clear()
    this.regulationLayer.layer.painter.currentTime = undefined
  }

  prepareTrajectoryLayerForRegulation (regulationId, layerIndex) {
    this.trajectoryLayers[layerIndex].store.reload(TimelapseDataProvider.getFlightsForRegulation(regulationId))
  }

  prepareTrajectoryLayerForRemainingFlights () {
    const remainingFlights = TimelapseDataProvider.getFlightsForRegulation(this.remainingFlightsKey)

    let i = 0
    const loadLimit = 500
    this.remainingFlightLoadTimerID = setInterval(() => {
      const startIndex = i * loadLimit
      let endIndex = (i + 1) * loadLimit
      endIndex = remainingFlights.length <= endIndex ? remainingFlights.length : endIndex

      if (remainingFlights.length > startIndex) {
        for (let j = startIndex; j < endIndex; j++) {
          this.trajectoryLayers[4].store.put(remainingFlights[j])
        }
      } else {
        //loading is complete
        clearInterval(this.remainingFlightLoadTimerID)
      }
      i++
    }, 2000)
  }

  prepareRegulationsLayer () {
    this.regulationLayer.store.reload(TimelapseDataProvider.get24hRegulations())
  }

  startTimelapse () {
    if (TimelapseDataProvider.anyExistingTrajectories()) {
      this.runRegulatedFlightsTimeLapse(0)
    } else {
      this.displayLoadingPanel(false)
      this.runRemainingFlightsTimeLapse()
    }
  }

  runRegulatedFlightsTimeLapse (layerIndex) {
    window.cancelAnimationFrame(this.animationFrameId)
    this.regulationLayer.store.clear()

    const regulationId = this.mostSignificantRegulations[layerIndex]
    const regulationFeature = TimelapseDataProvider.getRegulationFeature(regulationId)
    if (regulationFeature) {
      this.regulationLayer.store.put(regulationFeature)
    }

    this.displayLoadingPanel(false)
    const bounds = getWorldFitBounds(regulationFeature.shape.bounds)
    const fitPromise = this.map.mapNavigator.fit({ bounds: bounds, fitMargin: '70%', animate: true })
    this.animatingRegulations = true
    fitPromise.then(() => {
      const dataSetStartTime = (regulationFeature.properties.startTime - 6000) * 1000
      const dataSetEndTime = (regulationFeature.properties.startTime + regulationFeature.properties.duration + 2000) * 1000
      this.animateTimeLabel(false)
      this.lastDataTime = dataSetStartTime
      this.prepareTrajectoryLayerForRegulation(regulationId, layerIndex)
      this.lasttime = Date.now()
      this.displayTimeLabel(true)
      this.setTimeLabel(this.lastDataTime / 1000.0)
      this.scheduleTimeWindowRegulatedFlights(this.trajectoryLayers[layerIndex].layer, dataSetStartTime, dataSetEndTime, layerIndex)
      setTimeout(() => {
        if (regulationFeature.shape.type === ShapeType.POLYGON) {
          this.map.mapNavigator.zoom({ factor: 0.6, animate: { duration: 4000 } })
        } else if (regulationFeature.shape.type === ShapeType.POINT) {
          this.map.mapNavigator.zoom({ factor: 0.3, animate: { duration: 4000 } })
        }

      }, 2000)
    })
  }

  runRemainingFlightsTimeLapse () {
    window.cancelAnimationFrame(this.animationFrameId)
    this.prepareTrajectoryLayerForRemainingFlights()
    this.prepareRegulationsLayer()
    //hide any baloons that are displayed
    this.map.hideBalloon()

    const dataSetStartTime = moment().startOf('day').valueOf() // set to 12:00 am today
    const dataSetEndTime = moment().startOf('day').add(1, 'days').valueOf() // set to 12:00 am tomorrow
    this.animateTimeLabel(true)
    this.setTimeLabel(dataSetStartTime / 1000.0)

    this.regulationLayer.layer.painter.currentTime = dataSetStartTime
    this.regulationLayer.layer.painter.invalidateAll()

    this.lasttime = Date.now()
    this.lastDataTime = dataSetStartTime

    this.scheduleTimeWindowRemainingFlights(this.trajectoryLayers[4].layer, dataSetEndTime)

    this.animate(animationList[getRandomInt(animationList.length)])
  }

  animate (animations, index) {
    index = index ? index : 0
    if (index < animations.length) {
      const animation = animations[index]
      const promise = animation.function(this.map)
      promise.then(() => {
        setTimeout(() => {
          this.animate(animations, index + 1)
        }, animation.duration)
      })
    } else {
      const mapState = getRandomInt(2) === 1 ? this.mapState : this.mapState2
      this.map.restoreState(mapState, { animate: { duration: 4500 } })
    }
  }

  scheduleTimeWindowRegulatedFlights (trajectorylayer, dataSetStartTime, dataSetEndTime, layerIndex) {
    const currTime = Date.now()
    const deltaTime = (currTime - this.lasttime)
    this.lasttime = currTime
    let newTime = this.lastDataTime + (this.speedupRegulatedFlights * deltaTime)
    this.lastDataTime = newTime
    if (newTime >= dataSetEndTime) {//timelapse is over for the regulation
      layerIndex++
      if (layerIndex < this.mostSignificantRegulations.length) {
        this.animateTimeLabel(true)
        const nextDataSetStartTime = (TimelapseDataProvider
          .getRegulationFeature(this.mostSignificantRegulations[layerIndex]).properties.startTime - 6000)
        this.setTimeLabel(nextDataSetStartTime)
        this.runRegulatedFlightsTimeLapse(layerIndex)
      } else {
        this.animatingRegulations = false
        this.runRemainingFlightsTimeLapse()
      }
      return
    }

    trajectorylayer.painter.timeWindow = [dataSetStartTime, (this.lastDataTime + this.lengthOfTail)]
    this.setTimeLabel(this.lastDataTime / 1000.0)
    this.animationFrameId = window.requestAnimationFrame(function () {
      this.scheduleTimeWindowRegulatedFlights(trajectorylayer, dataSetStartTime, dataSetEndTime, layerIndex)
    }.bind(this))
  }

  scheduleTimeWindowRemainingFlights (trajectorylayer, dataSetEndTime) {
    const currTime = Date.now()
    const deltaTime = (currTime - this.lasttime)
    this.lasttime = currTime

    let newTime = this.lastDataTime + (this.speedupRemaningFlights * deltaTime)
    this.lastDataTime = newTime
    if (newTime >= dataSetEndTime) {//timelapse is over for the remaining flights
      clearInterval(this.remainingFlightLoadTimerID)
      const dataReady = TimelapseDataProvider.prepareData(this.remainingFlightsKey)
      this.clearLayers()
      this.displayTimeLabel(false)
      this.displayLoadingPanel(true)
      dataReady.then((regulations) => {//after all the data is collected restart the time lapse sequence
        this.mostSignificantRegulations = regulations.filter(id => id !== this.remainingFlightsKey)
        this.startTimelapse()
      })
      return
    }

    this.regulationLayer.layer.painter.currentTime = this.lastDataTime / 1000.0
    this.regulationLayer.layer.painter.invalidateAll()

    trajectorylayer.painter.timeWindow = [this.lastDataTime, (this.lastDataTime + this.lengthOfTail)]
    this.setTimeLabel(this.lastDataTime / 1000.0)
    this.animationFrameId = window.requestAnimationFrame(function () {
      this.scheduleTimeWindowRemainingFlights(trajectorylayer, dataSetEndTime)
    }.bind(this))
  }

  /**
   * Creates the DOM node to display the clock on the map
   */
  createTimeDOMNode () {
    let timeLabel = document.createElement('div')
    timeLabel.setAttribute('id', 'onMapTimeLabel')
    timeLabel.innerHTML = '<span id="livetimelabel" class="upLeft2 noselect" ></span> '
    this.map.domNode.appendChild(timeLabel)
  }

  /**
   * Creates the DOM node to display the clock on the map
   */
  createPredictedDataDOMNode () {
    let predictionLabel = document.createElement('div')
    predictionLabel.setAttribute('id', 'onMapPredictionLabel')
    predictionLabel.innerHTML = '<span id="predictionlabel" class="upLeft noselect" >Today’s traffic as forecast by<br>the EUROCONTROL Network Manager</span>'
    this.map.domNode.appendChild(predictionLabel)
  }

  createLoadingWindowDOMNode () {
    const loadingPanel = document.createElement('div')
    loadingPanel.setAttribute('id', 'loadingpanelpredict')
    loadingPanel.classList.add('loadingpanelpredict')
    loadingPanel.style.textAlign = 'center'

    const infoPanel = document.createElement('div')
    infoPanel.setAttribute('id', 'infopanel')
    infoPanel.classList.add('infopanel')
    infoPanel.innerHTML = getLoadingLabel()
    loadingPanel.appendChild(infoPanel)

    this.map.domNode.appendChild(loadingPanel)
    this.displayLoadingPanel(false)
  }

  notifyMeasurePainted (feature, shape) {
    if (feature.measureType === 'Regulation' && this.animatingRegulations) {
      this.map.showBalloon({
        object: feature,
        layer: this.regulationLayer.layer,
        contentProvider: constructRegulationLabelContent,
        panTo: false
      })
    }
  }

  setTimeLabel (currentTime) {
    document.getElementById('livetimelabel').innerHTML = moment.unix(currentTime).format('HH:mm')
  }

  animateTimeLabel (isAnimate) {
    const timeLabelElement = document.getElementById('livetimelabel')
    const predictionLabelElement = document.getElementById('predictionlabel')
    if (isAnimate) {
      predictionLabelElement.classList.add('flip-scale-up-hor')
      timeLabelElement.classList.add('flip-scale-up-hor')
    } else {
      predictionLabelElement.classList.remove('flip-scale-up-hor')
      timeLabelElement.classList.remove('flip-scale-up-hor')
    }
  }

  displayTimeLabel (isVisible) {
    const opacity = isVisible ? 1 : 0
    document.getElementById('livetimelabel').style.opacity = opacity
  }

  displayLoadingPanel (isVisible) {
    const opacity = isVisible ? 1 : 0
    document.getElementById('loadingpanelpredict').style.opacity = opacity
  }

  render () {
    return (<MapView id={this.props.id}/>)
  }
}

TimelapsePredictMapController
  .propTypes = {
  id: PropTypes.string.isRequired
}