import { getRandomInt } from '../../../../common/utils'

const regulationLabels = new Map()
const reroutingLabels = new Map()

// const loadingLabel = [
//   'Watch',
//   'EUROCONTROL',
//   'optimise the continent\'s busy skies',
// ];

const loadingLabel = [
  'Timelapse of today\'s key',
  'air traffic management events',
  'on the European network'
];

// init the regulationLabels map
(function initRegulationLabelsMap () {

  const regulationAtcCapacityLabels = [
    'Around {nrImpactedFlights} flights are regulated in {name}\\n to balance capacity with demand.',
    'Meanwhile at {name}, around {nrImpactedFlights} flights regulated\\n to optimise air traffic efficiency.',
    'Around {nrImpactedFlights} flights are regulated at {name}\\n to manage delays resulting from reduced ATC capacity.',
    'Around {nrImpactedFlights} flights are regulated\\n to minimise the effects of limited ATC capacity at {name}.'
  ]

  const regulationAerodromeCapacityLabels = [
    '{name} is very busy today! \\n Around {nrImpactedFlights} flights are regulated to minimise delay.',
    'Around {nrImpactedFlights} flights are regulated at {name}\\n to balance capacity with demand.',
    'Around {nrImpactedFlights} flights are regulated at {name}\\n to ensure seamless air traffic throughout the network.',
    'Around {nrImpactedFlights} flights are regulated\\n to minimise the effects of limited aerodrome capacity at {name}.',
    'Meanwhile at {name}, around {nrImpactedFlights} flights\\n are regulated to ensure the best network efficiency.'
  ]

  const regulationAerodoromeServicesLabels = [
    'Due to a degradation of services at {name},\\n around {nrImpactedFlights} flights are regulated to minimise delay.'
  ]

  const regulationSpecialEventLabels = [
    'At {name} around {nrImpactedFlights} flights are regulated to minimise delay.',
    'Around {nrImpactedFlights} flights are regulated at {name}\\n to ensure seamless air traffic throughout the network.',
    'Around {nrImpactedFlights} flights are regulated at {name}\\n to optimise air traffic efficiency.'
  ]

  const regulationAirspaceManagementLabels = [
    '{name} is very active today!\\nAround {nrImpactedFlights} flights are regulated to minimise delay.',
    'To balance capacity with demand,\\n around {nrImpactedFlights} flights are regulated.',
    'Around {nrImpactedFlights} flights are regulated \\n to ensure seamless air traffic in {name}.'
  ]

  const regulationAtcEquipmentLabels = [
    'Around {nrImpactedFlights} flights are regulated\\n to manage the impact of ATC equipment issues experienced at {name}.'
  ]

  const regulationAtcRoutingLabels = [
    'Around {nrImpactedFlights} flights are regulated at {name}\\n due to ATC routings.'
  ]
  const regulationAtcActionLabels = [
    'Around {nrImpactedFlights} flights are regulated at {name}\\n due to ATC industrial action. ',
    'Due to ATC industrial action at {name}, \\n around {nrImpactedFlights} flights are regulated to minimise disruptions.'
  ]

  const regulationAtcStaffingLabels = [
    'Due to an ATC staffing issue at {name},\\n around {nrImpactedFlights} flights are regulated to minimise disruptions.',
    'Due to an ATC staffing issue at {name},\\n around {nrImpactedFlights} flights are regulated to minimise the impact on the overall network performance.'
  ]

  const regulationIncidentAccidentLabels = [
    'Around {nrImpactedFlights} flights are regulated at {name}.',
  ]

  const regulationNonAtcActionLabels = [
    'Due to a non-ATC related industrial action at {name},\\n around {nrImpactedFlights} flights are regulated to minimise disruptions.'
  ]

  const regulationWeatherLabels = [
    'Around {nrImpactedFlights} flights are regulated at {name} due to bad weather.'
  ]

  const regulationEnvironmentalLabels = [
    'Around {nrImpactedFlights} flights are regulated\\n due to an environmental issue near {name}'
  ]

  regulationLabels.set('ATC CAPACITY', regulationAtcCapacityLabels)
  regulationLabels.set('ACCIDENT INCIDENT', regulationIncidentAccidentLabels)
  regulationLabels.set('AERODROME SERVICES', regulationAerodoromeServicesLabels)
  regulationLabels.set('AERODROME CAPACITY', regulationAerodromeCapacityLabels)
  regulationLabels.set('ATC INDUSTRIAL ACTION', regulationAtcActionLabels)
  regulationLabels.set('NON ATC INDUSTRIAL ACTION', regulationNonAtcActionLabels)
  regulationLabels.set('WEATHER', regulationWeatherLabels)
  regulationLabels.set('AIRSPACE MANAGEMENT', regulationAirspaceManagementLabels)
  regulationLabels.set('SPECIAL EVENT', regulationSpecialEventLabels)
  regulationLabels.set('ATC ROUTINGS', regulationAtcRoutingLabels)
  regulationLabels.set('ATC STAFFING', regulationAtcStaffingLabels)
  regulationLabels.set('ATC EQUIPMENT', regulationAtcEquipmentLabels)
  regulationLabels.set('ENVIRONMENTAL ISSUES', regulationEnvironmentalLabels)
  regulationLabels.set('OTHERS', regulationSpecialEventLabels)
})();

// init the reroutingLabels map
(function initReroutingLabelsMap () {

  const reroutingAtcCapacityLabels = [
    'Up to {nrImpactedFlights} flights managed by  \\n {name} are being  rerouted \\n  to avoid delays due to ATC \\n capacity issues today.',
    'Up to {nrImpactedFlights} flights are being  \\n rerouted to help optimise \\n the efficiency  of the airspace \\n managed by {name}.',
    'Up to {nrImpactedFlights} flights managed by \\n {name} are being rerouted \\n today to limit delays caused \\n by reduced ATC capacity.',
     'Up to {nrImpactedFlights} flights are being  \\n rerouted in this area. \\n  This helps mitigate the effect of \\n reduced ATC capacity in part of \\n  the airspace managed by {name}.'
  ]

  const reroutingAerodromeCapacityLabels = [
    '{name} is quite busy today! \\n  We\'re working closely \\n with the teams involved\\n  to make sure that  most flights \\n  can depart or arrive on time.',
    'Up to {nrImpactedFlights} flights are being \\n rerouted at {name} to balance \\n capacity with demand.',
    'Up to {nrImpactedFlights} flights are being \\n rerouted at {name} to ensure  \\n seamless air traffic throughout  \\n the network.',
    'Up to {nrImpactedFlights} flights are being  \\n rerouted to mitigate the \\n  impact  of reduced \\n  capacity at {name}.',
    'Meanwhile at {name},  up to \\n {nrImpactedFlights} flights  are rerouted to make \\n sure the overall efficiency of \\n  the European network.'
  ]

  const reroutingAerodoromeServicesLabels = [
    'Due to a reduced level of \\n service at {name}, up to {nrImpactedFlights} \\n flights are being rerouted to \\n prevent major delays.'
  ]

  const reroutingSpecialEventLabels = [
    'Up to {nrImpactedFlights} flights are being \\n rerouted to minimise disruptions \\n on the overall network.',
    'Up to {nrImpactedFlights} flights are being \\n rerouted to optimise \\n capacity in this area.'
  ]

  const reroutingAirspaceManagementLabels = [
    '{name} is quite busy today!\\n Around {nrImpactedFlights} flights are \\n rerouted to minimise delay.',
    'To balance capacity with demand, \\n around {nrImpactedFlights} flights are rerouted.',
    'Up to {nrImpactedFlights} flights are being rerouted \\n to ensure seamless air traffic in the \\n airspace managed by {name}.'
  ]

  const reroutingAtcEquipmentLabels = [
    'Up to {nrImpactedFlights} flights are being rerouted \\n to mitigate the impact of ATC \\n equipment issues experienced at {name}.'
  ]

  const reroutingAtcRoutingLabels = [
    'Up to {nrImpactedFlights} flights are being rerouted \\n at {name} due to ATC routings.'
  ]
  const reroutingAtcActionLabels = [
    'Up to {nrImpactedFlights} flights are being \\n rerouted around the airspace\\n  managed by {name} \\n to limit the impact of the \\n on-going ATC industrial action. ',
    'Due to an on-going ATC  \\n industrial action at {name}, \\nup to {nrImpactedFlights} flights are \\n being rerouted.'
  ]

  const reroutingAtcStaffingLabels = [
    'Due to an ATC staffing \\n issue  at {name}, up to {nrImpactedFlights} \\n flights are being rerouted \\n to minimise disruptions.',
    'Due to an ATC staffing issue  \\n at {name}, up to {nrImpactedFlights} flights  \\n are being rerouted to minimise \\n the impact on the overall \\n performance of the network.'
  ]

  const reroutingIncidentAccidentLabels = [
    'Up to {nrImpactedFlights} flights are being \\n rerouted around the airspace \\n managed by {name}.'
  ]

  const reroutingNonAtcActionLabels = [
    'Due to a non-ATC related \\n industrial action at {name}, \\n up to {nrImpactedFlights} flights are being \\n rerouted to minimise disruptions \\n on the overall network.'
  ]

  const reroutingWeatherLabels = [
    'Up to {nrImpactedFlights} flights are being \\n rerouted around the airspace \\n managed by {name} due to \\n bad weather.'
  ]

  const reroutingEnvironmentalLabels = [
    'Up to {nrImpactedFlights} flights are  rerouted \\n due to an environmental \\n issue in the vicinity of \\n the airspace managed by {name}'
  ]

  reroutingLabels.set('ATC CAPACITY', reroutingAtcCapacityLabels)
  reroutingLabels.set('ACCIDENT INCIDENT', reroutingIncidentAccidentLabels)
  reroutingLabels.set('AERODROME SERVICES', reroutingAerodoromeServicesLabels)
  reroutingLabels.set('AERODROME CAPACITY', reroutingAerodromeCapacityLabels)
  reroutingLabels.set('ATC INDUSTRIAL ACTION', reroutingAtcActionLabels)
  reroutingLabels.set('NON ATC INDUSTRIAL ACTION', reroutingNonAtcActionLabels)
  reroutingLabels.set('WEATHER', reroutingWeatherLabels)
  reroutingLabels.set('AIRSPACE MANAGEMENT', reroutingAirspaceManagementLabels)
  reroutingLabels.set('SPECIAL EVENT', reroutingSpecialEventLabels)
  reroutingLabels.set('ATC ROUTINGS', reroutingAtcRoutingLabels)
  reroutingLabels.set('ATC STAFFING', reroutingAtcStaffingLabels)
  reroutingLabels.set('ATC EQUIPMENT', reroutingAtcEquipmentLabels)
  reroutingLabels.set('ENVIRONMENTAL ISSUES', reroutingEnvironmentalLabels)
  reroutingLabels.set('OTHERS', reroutingSpecialEventLabels)
})()

export function getLabelForRegulationReason (regulationReason) {
  const labelList = regulationLabels.get(regulationReason)
  if (labelList) {
    const labelListSize = labelList.length
    return labelListSize > 1 ? labelList[getRandomInt(labelListSize)] : labelList[0]
  }

  return ''
}

export function getLabelForReroutingReason (reroutingReason) {
  const labelList = reroutingLabels.get(reroutingReason)
  if (labelList) {
    const labelListSize = labelList.length
    return labelListSize > 1 ? labelList[getRandomInt(labelListSize)] : labelList[0]
  }

  return ''
}

export function getLoadingLabel () {
  return '<h1>' + loadingLabel[0] + '</h1><h1>' + loadingLabel[1] + '</h1><h1>' + loadingLabel[2] + '</h1>'

}