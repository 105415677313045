import * as React from 'react'
import TrafficSituationKpi from './traffic-situation-kpi'
import './traffic-situation.css'

type Props = {
  kpis: {
    airborne: {
      value: int
    },
    landed: {
      value: int
    },
    planned: {
      value: int
    },
    totalFlights: {
      value: int
    },
    totalDelay: {
      value: int
    }
  }
}

export default class TrafficSituation extends React.Component<Props> {

  render () {
    return <section className="traffic-situation">
      <h5>traffic situation</h5>
      <div className="container">
         <span className="big-icons">
          <TrafficSituationKpi icon='delay' kpiType='large' title='delay (min)' value={this.props.kpis.totalDelay.value}/>
          <TrafficSituationKpi icon='total' kpiType='large' title='flights (total)' value={this.props.kpis.totalFlights.value}/>
         </span>
        <span className="small-icons">
          <TrafficSituationKpi icon='planned' title='planned' value={this.props.kpis.planned.value}/>
          <TrafficSituationKpi icon='airborne' title='airborne' value={this.props.kpis.airborne.value}/>
          <TrafficSituationKpi icon='landed' title='landed' value={this.props.kpis.landed.value}/>
        </span>
      </div>
    </section>
  }
}