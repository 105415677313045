import * as ShapeFactory from '@luciad/ria/shape/ShapeFactory'
import * as TransformationFactory from '@luciad/ria/transformation/TransformationFactory'
import { COMMON_SPATIAL_REFERENCE, GLOBE_SPATIAL_REFERENCE } from '../bounds'

const animationParametersList = [{
  eyePoint: [-6.373944486044591, 36.390770445674455, 989683.3924925253],//europe from spain
  pitch: -62.978530492869254,
  roll: -0.30613343730010534,
  yaw: 16.28220182943972

}, {
  // eyePoint: [10.405275759717545, 45.187968187765456, 120623.51620824914] // pan to middle europe
  eyePoint: [24.873047, 46.407564, 520623.51620824914] // pan to middle europe
}, {
  eyePoint: [25.400391, 34.615127, 1389683.3924925253],//europe from greece
  pitch: -45.978530492869254,
  roll: -0.30613343730010534,
  yaw: -20.28220182943972

}, {
  eyePoint: [-19.599609, 61.648162, 19623.51620824914] // pan to the UK
}, {
  eyePoint: [-19.599609, 61.648162, 1000623.51620824914],
  pitch: -45.978530492869254,
  roll: -0.30613343730010534,
  yaw: -240.28220182943972

}]

export const animationList = [
  [
    { function: firstAnimationEurope, duration: 5000 },
    { function: secondAnimationEurope, duration: 4500 },
    { function: thirdAnimationEurope, duration: 5000 }
  ],
  [
    { function: fourthAnimationEurope, duration: 5000 },
    { function: fifthAnimationEurope, duration: 4000 },
    { function: sixthAnimationEurope, duration: 4500 }
  ]
]

function firstAnimationEurope (map) {
  return map.mapNavigator.lookFrom(
    ShapeFactory.createPoint(COMMON_SPATIAL_REFERENCE, animationParametersList[0].eyePoint),
    animationParametersList[0].yaw,
    animationParametersList[0].pitch,
    animationParametersList[0].roll,
    { animate: { duration: 3000 } })
}

function secondAnimationEurope (map) {
  return map.mapNavigator.pan({
    targetLocation: ShapeFactory.createPoint(COMMON_SPATIAL_REFERENCE,
      animationParametersList[1].eyePoint),
    animate: { duration: 2500 }
  })
}

function thirdAnimationEurope (map) {
  return map.mapNavigator.rotate({
    center: ShapeFactory.createPoint(COMMON_SPATIAL_REFERENCE,
      animationParametersList[1].eyePoint),
    deltaYaw: -100,
    deltaPitch: 30,
    animate: { duration: 5000 }
  })
}

function fourthAnimationEurope (map) {
  return map.mapNavigator.lookFrom(
    ShapeFactory.createPoint(COMMON_SPATIAL_REFERENCE, animationParametersList[2].eyePoint),
    animationParametersList[2].yaw,
    animationParametersList[2].pitch,
    animationParametersList[2].roll,
    { animate: { duration: 3500 } })
}

function fifthAnimationEurope (map) {
  return map.mapNavigator.pan({
    targetLocation: ShapeFactory.createPoint(COMMON_SPATIAL_REFERENCE,
      animationParametersList[3].eyePoint),
    animate: { duration: 3700 }
  })
}

function sixthAnimationEurope (map) {
  return map.mapNavigator.lookFrom(
    ShapeFactory.createPoint(COMMON_SPATIAL_REFERENCE, animationParametersList[4].eyePoint),
    animationParametersList[4].yaw,
    animationParametersList[4].pitch,
    animationParametersList[4].roll,
    { animate: { duration: 6000 } })
}

export function getWorldFitBounds (bounds) {
  const transformation = TransformationFactory.createTransformation(bounds.reference, GLOBE_SPATIAL_REFERENCE)
  const transformedBounds = transformation.transformBounds(bounds)
  return _justifyBounds(transformedBounds)
}

function _justifyBounds (bounds) {

  let boundsCopy

  if (bounds.width < 1e-5 && bounds.height < 1e-5) {
    boundsCopy = bounds.copy()
    const width = 1e5
    const x = boundsCopy.x - width / 2
    const y = boundsCopy.y - width / 2
    boundsCopy.setTo2D(x, width, y, width)
  } else {
    boundsCopy = bounds
  }

  return boundsCopy
}