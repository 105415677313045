import * as React from 'react'
import { formatValue } from '../../../../common/formats'
import './traffic-situation-kpi.css'
import Icon from './icon'

export default class TrafficSituationKpi extends React.Component<Props> {

  render () {
    const kpiType = this.props.kpiType ? '-large' : ''
    return <div className="traffic-situation-kpi">
      <Icon id={this.props.icon}/>
      <div className={'title' + kpiType}>{this.props.title}</div>
      <div className={'value' + kpiType}>{formatValue(this.props.value)}</div>
    </div>
  }
}