// @flow

import type { TickerActions } from '../actions/ticker'

export type TickerState = {
  text: string
}

const initial = {
  text: ''
}

export default function ticker (state: TickerState = initial, action: TickerActions): TickerState {
  switch(action.type) {
    case 'UPDATE_TICKER':
      return { text: action.payload.bannerText }
    default:
      return state
  }
}
