import { postLog, registerClient } from '../services/api'
import cfg from '../config'

export const LogType = Object.freeze({
  DEBUG: 'DEBUG',
  WARNING: 'WARNING',
  INFO: 'INFO',
  ERROR: 'ERROR',
  TRACE: 'TRACE'
})

export function getClientId () {
  if (!sessionStorage.getItem('clientId')) {
    registerClient(cfg.urls.registerClient).then(id => {
      sessionStorage.setItem('clientId', id)
    })
  }
}

export function serverLog (source, logType, messeage, params) {
  const clientId = sessionStorage.getItem('clientId')
  let payload = {
    clientId: clientId,
    logType: logType,
    message: messeage,
    parameters: params

  }

  if (source === 'animation') {
    postLog(cfg.urls.clientLog, payload)
  }
}