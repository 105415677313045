import Promise from 'promise'
import { API_OFFLINE } from '../common/errors'
import { setOfflineIndicator } from '../redux/actions/updater'
import updaterCfg from './updater-cfg'

export default class Updater {
  /**
   * Creates Updater, which will push updated data to redux store
   * @param store
   * @param interval
   * @param apis list of apis to be queried, format TBD, for now will call all endpoints
   */
  constructor (store, interval = 30000) {
    if (!store) throw new Error('Updater needs store parameter!')
    this.store = store
    this.interval = interval
    this.loop = null
    this.apis = {}
    this.runner = () => {
      const apis2Call = Object.keys(this.apis)
      const calls = apis2Call.map(i => {
        const ep = updaterCfg[i]
        if (!ep) return null
        return ep.apiGet()
          .then(res => {
            if (ep.postprocess) {
              return ep.postprocess(res)
            } else {
              return res
            }
          })
          .then(res => {
            return ep.action(res)(this.store.dispatch)
          })
          .catch(err => {
            if (err.type === API_OFFLINE) {
              setOfflineIndicator(i)(this.store.dispatch)
            } else {
              console.debug(err)
            }
          })
      })
      return Promise.all(calls)
    }
  }
  addSubscription (apiRef) {
    if (!apiRef) return false
    this.apis[apiRef] = this.apis[apiRef] ? this.apis[apiRef] + 1 : 1
    return true
  }
  delSubscription (apiRef) {
    if (!apiRef || !this.apis[apiRef]) return false
    this.apis[apiRef] = this.apis[apiRef] - 1
    if (this.apis[apiRef] === 0) {
      delete this.apis[apiRef]
    }
    return true
  }
  start () {
    if (this.loop) return // Already running a loop
    this.runner()
    this.loop = setInterval(this.runner, this.interval)
  }

  stop () {
    if (!this.loop) return // Already stopped a loop
    clearInterval(this.loop)
    this.loop = null
  }
}
