import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import './uploadfile.css'
import { uploadFile } from '../../services/api'
import cfg from '../../config'

class UploadFile extends Component {

  constructor (props) {
    super(props)

    this.state = {
      uploadedFile: {},
      uploadResult: -1,
      errorMesssage: '',
      errorCode: ''
    }

    let self: any = this
    self.onDrop = this.onDrop.bind(this)
  }

  onDrop (acceptedFiles, rejectedFiles) {
    if (rejectedFiles.length === 0 && acceptedFiles.length === 1) {
      uploadFile(cfg.urls.uploadFile, acceptedFiles[0]).then(successResponse => {
          this.setState({
            uploadedFile: {name: successResponse.fileName},
            uploadResult: 1,
            errorMesssage: '',
            errorCode: ''
          })
        },
        failureResponse => {
          this.setState({
            uploadedFile: '',
            uploadResult: 0,
            errorMesssage: failureResponse.message,
            errorCode: failureResponse.code
          })
        })
    }
  }

  render () {
    const maxSize = 200000000

    const uploadResultStyle = this.state.uploadResult ? 'successMessage' : 'failureMessage'
    const uploadResultMessage = this.state.uploadResult >= 0 ? (this.state.uploadResult === 1 ? <p>The file {this.state.uploadedFile.name} has been
        uploaded successfully.</p> :
      <p>The file {this.state.uploadedFile.name} could not be uploaded. Due to error: {this.state.errorMesssage} Error code {this.state.errorCode}.</p>) : ''
    return (
      <form>
        <div>
          <Dropzone
            onDrop={this.onDrop}
            accept='text/csv, application/vnd.ms-excel'
            minSize={0}
            maxSize={maxSize}
            multiple={false}
          >
            {({getRootProps, getInputProps, isDragActive, isDragReject, isDragAccept, rejectedFiles}) => {
              const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize
              return (
                <div {...getRootProps({className: isDragReject ? 'DropzoneReject' : (isDragAccept ? 'DropzoneAccept' : 'Dropzone')})}>
                  <input {...getInputProps()} />
                  {!isDragActive && 'Drag \'n drop a file here, or click to select a file to upload! (Only *.csv files are allowed.)'}
                  {isDragActive && !isDragReject && 'Please drop the file to upload!'}
                  {isDragReject && 'File type not accepted, please upload only one *.csv file!'}
                  {isFileTooLarge && 'File is too large.'}
                </div>
              )
            }
            }
          </Dropzone>
          <div className={uploadResultStyle}>
            {uploadResultMessage}
          </div>
          <div className= 'downloadLink'>
            <a id="downloadLink" title="Click to download the  current scenario file"
               href={cfg.urls.downloadFile}>Download current file</a>
          </div>
        </div>
      </form>

    )
  }
}

export default UploadFile