// Thickness of circle in civil aerodrome (also a base for other aerodrome artefacts)
const CIRCLE_THICKNESS_RATIO = 7.5 / 100

// Thickness of rectangle used to display the cross of civil aerodrome
const RECTANGLE_THICKNESS_RATIO = 10 / 100

// Ratio used to compute the length of the rectangles displayed around the aerodrome
const RECTANGLE_LENGTH_RATIO_CIVIL = 20 / 100


const NOSE_FRONT_X_RATIO = 50 / 100
const NOSE_FRONT_Y_RATIO = 3 / 100
const NOSE_FRONT_BEZIER_LENGTH_RATIO = 5 / 100
const NOSE_BOTTOM_LEFT_X_RATIO = 40 / 100
const NOSE_BOTTOM_LEFT_Y_RATIO = 21 / 100
const NOSE_BOTTOM_RIGHT_X_RATIO = 60 / 100
const NOSE_BOTTOM_RIGHT_Y_RATIO = 21 / 100
const NOSE_BOTTOM_BEZIER_LENGTH_RATIO = 10 / 100
const WING_FRONT_ROOT_LEFT_X_RATIO = 40 / 100
const WING_FRONT_ROOT_LEFT_Y_RATIO = 35 / 100
const WING_FRONT_ROOT_RIGHT_X_RATIO = 60 / 100
const WING_FRONT_ROOT_RIGHT_Y_RATIO = 35 / 100
const WINGTIP_FRONT_LEFT_X_RATIO = 8 / 100
const WINGTIP_FRONT_LEFT_Y_RATIO = 53 / 100
const WINGTIP_FRONT_RIGHT_X_RATIO = 92 / 100
const WINGTIP_FRONT_RIGHT_Y_RATIO = 53 / 100
const WINGTIP_BACK_LEFT_X_RATIO = 8 / 100
const WINGTIP_BACK_LEFT_Y_RATIO = 67 / 100
const WINGTIP_BACK_RIGHT_X_RATIO = 92 / 100
const WINGTIP_BACK_RIGHT_Y_RATIO = 67 / 100
const WING_BACK_ROOT_LEFT_X_RATIO = 40 / 100
const WING_BACK_ROOT_LEFT_Y_RATIO = 60 / 100
const WING_BACK_ROOT_RIGHT_X_RATIO = 60 / 100
const WING_BACK_ROOT_RIGHT_Y_RATIO = 60 / 100
const TAIL_FRONT_ROOT_LEFT_X_RATIO = 40 / 100
const TAIL_FRONT_ROOT_LEFT_Y_RATIO = 76 / 100
const TAIL_FRONT_ROOT_RIGHT_X_RATIO = 60 / 100
const TAIL_FRONT_ROOT_RIGHT_Y_RATIO = 76 / 100
const TAILPLANE_FRONT_LEFT_X_RATIO = 30 / 100
const TAILPLANE_FRONT_LEFT_Y_RATIO = 85 / 100
const TAILPLANE_FRONT_RIGHT_X_RATIO = 70 / 100
const TAILPLANE_FRONT_RIGHT_Y_RATIO = 85 / 100
const TAILPLANE_BACK_LEFT_X_RATIO = 30 / 100
const TAILPLANE_BACK_LEFT_Y_RATIO = 96 / 100
const TAILPLANE_BACK_RIGHT_X_RATIO = 70 / 100
const TAILPLANE_BACK_RIGHT_Y_RATIO = 96 / 100
const TAIL_BACK_X_RATIO = 50 / 100
const TAIL_BACK_Y_RATIO = 90 / 100


export default class IconFactory{

  static BuildAirportIcon (aMainProperties) {
      const {width, height, lineColor, haloColor, haloWidth} = aMainProperties
  
      const canvas = IconFactory._createCanvas({width: width, height: height})
      const context = canvas.getContext('2d')
  
      const center_x = width / 2
      const center_y = height / 2
  
      const baseValue = Math.min(width, height) - 2 * haloWidth
  
      const circleRadius = baseValue * (30 / 100)
      const circleLineWidth = baseValue * CIRCLE_THICKNESS_RATIO
      const markWidth = baseValue * RECTANGLE_THICKNESS_RATIO
      const markLength = baseValue * RECTANGLE_LENGTH_RATIO_CIVIL
  
      // Render halo
      if (haloColor) {
        IconFactory._drawCircle(context, center_x, center_y, circleRadius, haloColor, circleLineWidth + 2 * haloWidth)
        IconFactory._drawMarks(context, center_x, center_y, circleRadius - haloWidth, haloColor, markWidth + 2 * haloWidth, markLength + 2 * haloWidth)
      }
  
      // Render line
      IconFactory._drawCircle(context, center_x, center_y, circleRadius, lineColor, circleLineWidth)
      IconFactory._drawMarks(context, center_x, center_y, circleRadius, lineColor, markWidth, markLength)
  
      return canvas
  }

  static BuildAirplaneIcon(aMainProperties) {
    const {width, height, lineColor, haloColor, haloWidth} = aMainProperties
  
    const canvas = IconFactory._createCanvas({ width: width, height: height})
    const context = canvas.getContext('2d')
   
    // Render airplane with halo
    if (haloColor) {
      context.fillStyle = lineColor
      context.strokeStyle = haloColor
      context.lineWidth = haloWidth
      IconFactory._drawAirplane(context, width, height)
      context.stroke()
    } else {
      // Just render the airplane
      context.fillStyle = lineColor
      IconFactory._drawAirplane(context, width, height)
    }

    return canvas
  }

  static _createCanvas(aSize) {
    var canvas = document.createElement('canvas');
    canvas.width = aSize.width;
    canvas.height = aSize.height;
    return canvas;
  }

  static _drawCircle (aContext, aCenterX, aCenterY, aRadius, aLineColor, aLineWidth) {
    aContext.lineWidth = aLineWidth
    aContext.strokeStyle = aLineColor
    aContext.beginPath()
    aContext.moveTo(aCenterX + aRadius, aCenterY);
    aContext.arc(aCenterX, aCenterY, aRadius, 0, 2 * Math.PI);
    aContext.stroke()
  }

  static _drawMarks (aContext, aCenterX, aCenterY, aRadius, aLineColor, aLineWidth, aLineLength) {
    aContext.lineWidth = aLineWidth
    aContext.fillStyle = aLineColor
    aContext.fillRect(aCenterX - aRadius - aLineLength, aCenterY - aLineWidth / 2, aLineLength, aLineWidth)
    aContext.fillRect(aCenterX + aRadius, aCenterY - aLineWidth / 2, aLineLength, aLineWidth)
    aContext.fillRect(aCenterX - aLineWidth / 2, aCenterY - aRadius - aLineLength, aLineWidth, aLineLength)
    aContext.fillRect(aCenterX - aLineWidth / 2, aCenterY + aRadius, aLineWidth, aLineLength)
  }
  
  static _drawAirplane(aContext, aWidth, aHeight) {
    aContext.moveTo(NOSE_BOTTOM_LEFT_X_RATIO * aWidth, NOSE_BOTTOM_LEFT_Y_RATIO * aHeight);
    aContext.bezierCurveTo(NOSE_BOTTOM_LEFT_X_RATIO * aWidth, NOSE_BOTTOM_LEFT_Y_RATIO * aHeight - NOSE_BOTTOM_BEZIER_LENGTH_RATIO * aWidth,
      NOSE_FRONT_X_RATIO * aWidth - NOSE_FRONT_BEZIER_LENGTH_RATIO * aWidth, NOSE_FRONT_Y_RATIO * aHeight,
      NOSE_FRONT_X_RATIO * aWidth, NOSE_FRONT_Y_RATIO * aHeight);
    aContext.bezierCurveTo(NOSE_FRONT_X_RATIO * aWidth + NOSE_FRONT_BEZIER_LENGTH_RATIO * aWidth, NOSE_FRONT_Y_RATIO * aHeight,
      NOSE_BOTTOM_RIGHT_X_RATIO * aWidth, NOSE_BOTTOM_RIGHT_Y_RATIO * aHeight - NOSE_FRONT_BEZIER_LENGTH_RATIO * aWidth,
      NOSE_BOTTOM_RIGHT_X_RATIO * aWidth, NOSE_BOTTOM_RIGHT_Y_RATIO * aHeight);
    aContext.lineTo(WING_FRONT_ROOT_RIGHT_X_RATIO * aWidth, WING_FRONT_ROOT_RIGHT_Y_RATIO * aHeight);
    aContext.lineTo(WINGTIP_FRONT_RIGHT_X_RATIO * aWidth, WINGTIP_FRONT_RIGHT_Y_RATIO * aHeight);
    aContext.lineTo(WINGTIP_BACK_RIGHT_X_RATIO * aWidth, WINGTIP_BACK_RIGHT_Y_RATIO * aHeight);
    aContext.lineTo(WING_BACK_ROOT_RIGHT_X_RATIO * aWidth, WING_BACK_ROOT_RIGHT_Y_RATIO * aHeight);
    aContext.lineTo(TAIL_FRONT_ROOT_RIGHT_X_RATIO * aWidth, TAIL_FRONT_ROOT_RIGHT_Y_RATIO * aHeight);
    aContext.lineTo(TAILPLANE_FRONT_RIGHT_X_RATIO * aWidth, TAILPLANE_FRONT_RIGHT_Y_RATIO * aHeight);
    aContext.lineTo(TAILPLANE_FRONT_RIGHT_X_RATIO * aWidth, TAILPLANE_FRONT_RIGHT_Y_RATIO * aHeight);
    aContext.lineTo(TAILPLANE_BACK_RIGHT_X_RATIO * aWidth, TAILPLANE_BACK_RIGHT_Y_RATIO * aHeight);
    aContext.lineTo(TAIL_BACK_X_RATIO * aWidth, TAIL_BACK_Y_RATIO * aHeight);
    aContext.lineTo(TAILPLANE_BACK_LEFT_X_RATIO * aWidth, TAILPLANE_BACK_LEFT_Y_RATIO * aHeight);
    aContext.lineTo(TAILPLANE_FRONT_LEFT_X_RATIO * aWidth, TAILPLANE_FRONT_LEFT_Y_RATIO * aHeight);
    aContext.lineTo(TAIL_FRONT_ROOT_LEFT_X_RATIO * aWidth, TAIL_FRONT_ROOT_LEFT_Y_RATIO * aHeight);
    aContext.lineTo(WING_BACK_ROOT_LEFT_X_RATIO * aWidth, WING_BACK_ROOT_LEFT_Y_RATIO * aHeight);
    aContext.lineTo(WINGTIP_BACK_LEFT_X_RATIO * aWidth, WINGTIP_BACK_LEFT_Y_RATIO * aHeight);
    aContext.lineTo(WINGTIP_FRONT_LEFT_X_RATIO * aWidth, WINGTIP_FRONT_LEFT_Y_RATIO * aHeight);
    aContext.lineTo(WING_FRONT_ROOT_LEFT_X_RATIO * aWidth, WING_FRONT_ROOT_LEFT_Y_RATIO * aHeight);
    aContext.lineTo(NOSE_BOTTOM_LEFT_X_RATIO * aWidth, NOSE_BOTTOM_LEFT_Y_RATIO * aHeight);
    aContext.fill();
  }
}





