// @flow

import * as GeodesyFactory from '@luciad/ria/geodesy/GeodesyFactory'
import { LineType } from '@luciad/ria/geodesy/LineType'
import { ShapeProvider } from '@luciad/ria/view/feature/ShapeProvider'
import type { CoordinateReference, Point, Polyline } from 'luciad'
import { getTimeInSeconds } from '../../../common/DateUtil'

type Plane = Point & {
  heading: number,
  hidden?: boolean
}

export function makeTrajectoryToPointShapeProvider (reference: CoordinateReference): ShapeProvider {
  let Geodesy = GeodesyFactory.createEllipsoidalGeodesy(reference)

  let shapeProvider = new ShapeProvider()
  shapeProvider.reference = reference
  shapeProvider.provideShape = function (trajectory) {
    const shape = ((trajectory.shape: any): Polyline)
    const timestamps = (trajectory.properties.timestamps: Array<number>)
    if (timestamps.length !== shape.pointCount) throw new TypeError('trajectory: len(timestamps) !== len(polyline)')

    let planeShape: Plane
    if (timestamps.length === 1) { // cannot interpolate from a single point...
      planeShape = ((shape.getPoint(0): any): Plane)
      planeShape.heading = 0
    } else {
      const now = getTimeInSeconds()
      if (timestamps[0] > now || timestamps[timestamps.length - 1] < now) { // not airborne
        planeShape = ((shape.getPoint(0): any): Plane)
        planeShape.heading = 0
        planeShape.hidden = true

      }

      let i = 0
      while (i < timestamps.length - 2 && timestamps[i] < now) {
        i++
      }
      let start = now - timestamps[i]
      let length = timestamps[i + 1] - timestamps[i]
      planeShape = ((Geodesy.interpolate(shape.getPoint(i), shape.getPoint(i + 1), start / length): any): Plane)
      planeShape.heading = Geodesy.forwardAzimuth(shape.getPoint(i), shape.getPoint(i + 1))
    }
    return planeShape
  }

  return shapeProvider
}

export function makeTrajectoryShapeProvider (reference: CoordinateReference): ShapeProvider {
  let Geodesy = GeodesyFactory.createEllipsoidalGeodesy(reference)

  let shapeProvider = new ShapeProvider()
  shapeProvider.reference = reference
  shapeProvider.provideShape = function (trajectory) {
    const shape = ((trajectory.shape: any): Polyline)
    const timestamps = (trajectory.properties.timestamps: Array<number>)
    if (timestamps.length !== shape.pointCount) throw new TypeError('trajectory: len(timestamps) !== len(polyline)')

    let planeShape: Plane
    if (timestamps.length === 1) { // cannot interpolate from a single point...
      planeShape = ((shape.getPoint(0): any): Plane)
      planeShape.heading = 0
    } else {
      let i = 0
      while (i < timestamps.length && timestamps[i] < this.currentTime) {
        i++
      }

      if (i === 0 || i === timestamps.length) { // the plane did not take off yet or has landed
        planeShape = ((shape.getPoint(0): any): Plane)
        planeShape.heading = 0
        planeShape.hidden = true
        return planeShape
      }

      let start = this.currentTime - timestamps[i - 1]
      let length = timestamps[i] - timestamps[i - 1]
      planeShape = ((Geodesy.interpolate(shape.getPoint(i - 1), shape.getPoint(i), start / length, LineType.SHORTEST_DISTANCE): any): Plane)
      planeShape.heading = Geodesy.forwardAzimuth(shape.getPoint(i - 1), shape.getPoint(i))
    }

    return planeShape
  }

  return shapeProvider
}
