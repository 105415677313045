import { UrlTileSetModel } from '@luciad/ria/model/tileset/UrlTileSetModel'

export default class WMTSTileSetModel extends UrlTileSetModel {

  constructor (options) {
    const newOpts = {
      reference: options.reference,
      level0Columns: options.level0Columns,
      level0Rows: options.level0Rows,
      levelCount: options.levelCount,
      tileWidth: options.tileWidth,
      tileHeight: options.tileHeight,
      bounds: options.bounds,
      baseURL: options.url +
        '?SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile&' +
        '&LAYER=' + options.layer +
        '&STYLE=' + options.style +
        '&FORMAT=' + options.format +
        '&TILEMATRIXSET=' + options.tileMatrixSet
    }

    super(newOpts)
    this.modelDescriptor = Object.assign({}, this.modelDescriptor, { description: 'OGC WMTS Model' })

    this.level0Rows = options.level0Rows
    this.tileMatrixSet = options.tileMatrixSet
    this.tileMatrices = options.tileMatrices
  }

  getTileURL (baseURL, tile) {
    const tileMatrix = this.tileMatrices[tile.level]
    return baseURL +
      '&TILEMATRIX=' + tileMatrix +
      '&TILEROW=' + ((this.level0Rows * Math.pow(2, tile.level)) - 1 - tile.y) +
      '&TILECOL=' + tile.x
  }

}