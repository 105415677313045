import {labels} from '../styles/palette'

/**
 * Returns string representing level of delay in minutes
 * @param delay
 * @returns {string}
 */
export function getDelayValue (delay = 0) {
  let result
  switch (delay) {
    case 0:
      result = '< 15'
      break
    case 1:
      result = '> 15'
      break
    case 2:
      result = '> 30'
      break
    case 3:
      result = '> 45'
      break
    default:
      result = '0'
  }
  return result
}

/**
 * returns label for a delay stated in minutes
 * @param delay {number}
 * @returns {string}
 */
export function getLabel4Delay (delay = 0) {
  let result
  switch (delay) {
    case 0:
      result = labels.REGULATED
      break
    case 1:
      result = labels.WARN
      break
    case 2:
      result = labels.ALERT
      break
    case 3:
      result = labels.CRITICAL
      break
    default:
      result = labels.NORMAL
  }
  return result
}


