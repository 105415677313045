// @flow

import * as React from 'react'
import './Canary.css'

type Props = {
  alive: boolean,
  color?: string
}

export default function Canary (props: Props) {
  const classNames = [
    'canary',
    props.alive ? '' : 'dead',
    props.color ? props.color : 'green'
  ]
  return <div className={classNames.join(' ').trim()}/>
}
