export const API_OFFLINE = 'API_OFFLINE'

/**
 * Used to process API offline errors
 */
export class APIError extends Error {
  constructor (type) {
    super()
    this.type = type
  }
}
