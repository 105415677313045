import React from 'react'
import { connect } from 'react-redux'
import cfg from '../../../config'
import Canary from '../../elements/Canary'
import HeadlineNews from '../../elements/director/headline-news'
import Logo from '../../elements/director/logo'
import SituationTable from '../../elements/director/situation-table'
import SlideShowIndicator from '../../elements/director/slide-show-indicator'
import TraficSituation from '../../elements/director/traffic-situation'
import { BackgroundType, DirectorMap } from '../../elements/map'
import './average-delay.css'

class AverageDelaysScreen extends React.Component {

  constructor (props) {
    super(props)

    this.updater = props.updater
    this.updater.addSubscription('TICKER')
    this.updater.addSubscription('KPIS_STATS')

    this.updater.addSubscription('airborne')
    this.updater.addSubscription('landed')
    this.updater.addSubscription('planned')
    this.updater.addSubscription('totalDelay')
    this.updater.addSubscription('totalFlights')

    this.updater.addSubscription('accumulatedDelays')
    this.updater.addSubscription('liveDelays')
    this.updater.start()

    this.state = { step: 1 }
  }

  componentDidMount () {
    this.timerId = setInterval(() => this.switchTable(), cfg.directorTableSwitchIntervalInMilliSeconds)
  }

  componentWillUnmount () {
    this.updater.delSubscription('TICKER')
    this.updater.addSubscription('KPIS_STATS')

    this.updater.delSubscription('airborne')
    this.updater.delSubscription('landed')
    this.updater.delSubscription('planned')
    this.updater.delSubscription('totalDelay')
    this.updater.delSubscription('totalFlights')

    this.updater.delSubscription('accumulatedDelays')
    this.updater.delSubscription('liveDelays')

    clearInterval(this.timerId)
  }

  switchTable () {
    this.setState({ step: +!this.state.step })
    setTimeout(() => this.forceUpdate(), 1000)
  }

  render () {
    let tableProps = [
      {
        data: this.props.liveDelays,
        title: 'live delay situation',
        headers: [<div>Display name</div>, <div>Delay in<br/> minutes</div>, <div>Avg. delay<br/>per flight</div>]
      },
      {
        data: this.props.accumulatedDelays,
        title: 'most delayed locations',
        headers: [<div>Location</div>, <div>Delay in<br/> minutes</div>, <div>Avg. delay<br/>per flight</div>]
      }
    ]

    const background = this.props.background || BackgroundType.MT_BASIC

    return <div className='screen director'>
      <DirectorMap key='Map' id='map-fullscreen' background={background}/>
      <Logo key='Logo'/>
      <HeadlineNews text={this.props.text}/>
      <TraficSituation kpis={this.props.kpis}/>
      <SituationTable {...tableProps[this.state.step]}/>
      <SlideShowIndicator step={this.state.step}/>
      <Canary key='Canary' alive={!this.props.compatible} color='red'/>
    </div>
  }

}

function mapStateToProps (state) {
  return {
    text: state.ticker.text,
    kpis: state.kpis,
    accumulatedDelays: state.topTables.accumulatedDelays,
    liveDelays: state.topTables.liveDelays,
    compatible: state.version.compatible
  }
}

export default connect(mapStateToProps)(AverageDelaysScreen)