import React from 'react'
import { connect } from 'react-redux'
import Canary from '../elements/Canary'
import Logo from '../elements/DisplayLogo'
import { Map, TimelapseLiveMap, TimelapsePredictMap } from '../elements/map'
import { TrajectoryPainterType } from '../elements/map/index'
import Ticker from '../elements/Ticker'
import './display-map.css'

class DisplayMap extends React.Component {

  constructor (props) {
    super(props)

    if (!this.props.trajectoryType || this.props.trajectoryType === TrajectoryPainterType.PLANEPAINTER) {
      this.updater = props.updater
      this.updater.addSubscription('TICKER')
      this.updater.start()
    }
  }

  componentWillUnmount () {
    this.updater && this.updater.delSubscription('TICKER')
  }

  render () {
    const componentList = []

    if (!this.props.trajectoryType || this.props.trajectoryType === TrajectoryPainterType.PLANEPAINTER) {
      componentList.push(<Map key='Map' id='map-fullscreen'/>)
      componentList.push(<Ticker key='Ticker'/>)
    } else if (this.props.trajectoryType === TrajectoryPainterType.TIMELAPSELIVEPAINTER) {
      componentList.push(<TimelapseLiveMap id='map-fullscreen' key='TimelapseLiveMap'/>)
      componentList.push(<Logo key='Logo'/>)
    } else if (this.props.trajectoryType === TrajectoryPainterType.TIMELAPSEPREDICTPAINTER) {
      componentList.push(<TimelapsePredictMap id='map-fullscreen' key='TimelapsePredictMap'/>)
      componentList.push(<Logo key='Logo'/>)
    }
    componentList.push(<Canary key='Canary' alive={!this.props.compatible} color='red'/>)

    return <div className='display-map'>
      {componentList}
    </div>
  }
}

function mapStateToProps (state) {
  return {
    compatible: state.version.compatible
  }
}

export default connect(mapStateToProps)(DisplayMap)