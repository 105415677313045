export const layersStyling = {
  DEFAULT_COLOR: 'rgb(82, 116, 255)',
  originLineStyle: {
    color: 'rgba(230, 20, 20, 0.6)',
    width: 2
  },
  lineStyle: {
    color: 'rgba(210,210,210,0.6)',
    width: 1
  },
  originLabelStyle: {
    fill: 'rgba(210,210,210,0.8)',
    halo: 'rgba(230, 20, 20, 0.8)',
    haloWidth: '3px',
    font: '12px sans-serif'
  },
  labelStyle: {
    fill: 'rgb(220,220,220)',
    halo: 'rgb(102,102,102)',
    haloWidth: '3px',
    font: '12px sans-serif'
  },
  backgroundLayer: {
    color: '#0e171d'
  },
  worldLayer: {
    color: '#1e303d'
  }
}

export const airspaceStyling = {
  DEFAULT_COLOR: 'rgba(204,0,51,0.7)',
  NO_COLOUR: 'rgba(255,255,255,0)',
  CRITICAL: 'rgba(255,0,0,0.8)',
  ALERT: 'rgba(255,122,0,0.8)',
  WARN: 'rgba(255,255,0,0.8)',
  REGULATED: 'rgba(255,255,225,0.8)'
}

export const reroutingStyling = 'rgba(202,255,253,0.3)'

export const labelStyling = {
  DEFAULT_COLOR: 'rgb(204,0,51)',
  NO_COLOUR: 'rgb(255,255,255)',
  CRITICAL: 'rgb(255,0,0)',
  ALERT: 'rgb(255,122,0)',
  WARN: 'rgb(255,255,0)',
  REGULATED: 'rgb(255,255,225)'
}

/**
 * maps 1:1 to css class applied to a label shown
 */
export const labels = {
  NORMAL: 'normal', // no color
  REGULATED: 'regulated', // white
  WARN: 'warn', // yellow
  ALERT: 'alert', // orange
  CRITICAL: 'critical' // red
}

/**
 * Airport icon urls by level of average delay
 */
export const airportIconUrls = {
  COMMON: '/images/airport_icon.png',
  NORMAL: '/images/airport_icon.png',
  REGULATED: '/images/airport_icon_regulated.png', // white
  WARN: '/images/airport_icon_warning.png', // yellow
  ALERT: '/images/airport_icon_alert.png', // orange
  CRITICAL: '/images/airport_icon_critical.png' // red
}

/**
 * Aerodorme icon urls by arriving/departing property
 */
export const aerodromeIconUrls = {
  ARIIVING_SMALL: {
    url: '/images/ad_arriving_36.png',
    width: '36px',
    height: '36px',
    zOrder: 8,
    draped: false
  },
  ARRIVING_MEDIUM: {
    url: '/images/ad_arriving_48.png',
    width: '48px',
    height: '48px',
    zOrder: 8,
    draped: false
  },
  DEPARING_SMALL: {
    url: '/images/ad_departing_36.png',
    width: '36px',
    height: '36px',
    zOrder: 8,
    draped: false
  },
  DEPARTING_MEDIUM: {
    url: '/images/ad_departing_48.png',
    width: '48px',
    height: '48px',
    zOrder: 8,
    draped: false
  }
}

export const planeIcons = {
  PLANE_08: {
    url: '/images/plane_08.png',
    width: '8px',
    height: '8px',
    offsetX: -4,
    offsetY: -4
  },
  PLANE_10: {
    url: '/images/plane_10.png',
    width: '10px',
    height: '10px',
    offsetX: -5,
    offsetY: -5
  },
  PLANE_14: {
    url: '/images/plane_14.png',
    width: '14px',
    height: '14px',
    offsetX: -7,
    offsetY: -7
  },
  PLANE_24: {
    url: '/images/plane_24.png',
    width: '24px',
    height: '24px',
    offsetX: -12,
    offsetY: -12
  },
  PLANE_34: {
    url: '/images/plane_34.png',
    width: '34px',
    height: '34px',
    offsetX: -17,
    offsetY: -17
  },
  VECTOR: size => ({
    url: `data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 249.84 248.25" height="${size}" width="${size}" version="1.0"><path id="path5724" d="M 247.51404,152.40266 139.05781,71.800946 c 0.80268,-12.451845 1.32473,-40.256266 0.85468,-45.417599 -3.94034,-43.266462 -31.23018,-24.6301193 -31.48335,-5.320367 -0.0693,5.281361 -1.01502,32.598388 -1.10471,50.836622 L 0.2842717,154.37562 0,180.19575 l 110.50058,-50.48239 3.99332,80.29163 -32.042567,22.93816 -0.203845,16.89693 42.271772,-11.59566 0.008,0.1395 42.71311,10.91879 -0.50929,-16.88213 -32.45374,-22.39903 2.61132,-80.35205 111.35995,48.50611 -0.73494,-25.77295 z" fill="yellow" fill-rule="evenodd"/></svg>`,
    width: `${size}px`,
    height: `${size}px`,
    offsetX: -Math.floor(size / 2),
    offsetY: -Math.floor(size / 2)
  })
}

export const directorAirspaceFillStyling = {
  NO_COLOUR: 'rgba(255,255,255,1)',
  CRITICAL: 'rgba(192,80,70,0.5)',
  ALERT: 'rgba(192,80,70,0.5)',
  WARN: 'rgba(211,105,8,0.5)',
  REGULATED: 'rgba(4,111,204,0.5)'
}

export const directorAverageDelayPalette = {
  NO_COLOUR: 'rgb(255,255,255)',
  REGULATED: 'rgb(4,111,204)',
  WARN: 'rgb(211,105,8)',
  ALERT: 'rgb(192,80,70)',
  CRITICAL: 'rgb(192,80,70)',
}

export const directorAirportIconStrokeStyling = 'rgba(253,253,253,0.5)'

export const airplaneIconStrokeStyling = 'rgb(163,0,20)'

export const airplaneIconFillStyling = 'rgb(255,255,0)'
