import { dispatchTickerUpdate } from '../redux/actions/ticker'
import { KPIS_STATS, setStatistics } from '../redux/actions/updater'
import { loadStatistics, loadTicker } from './api'

const updaterConfig = {}

updaterConfig[KPIS_STATS] = {
  apiGet: loadStatistics,
  postprocess: ({
    nrAirborneTraffic,
    nrLandedTraffic,
    nrPlannedTraffic,
    nrMinutesDelay,
    nrTotalTraffic,
    topAccumulatedDelays = [],
    topLiveDelays = []
  }) => {

    const liveDelays = topLiveDelays.map(a => {
      return {
        id: a.displayName,
        title: a.displayName,
        delay: a.delay,
        value: a.averageDelay
      }
    })

    const accumulatedDelays = topAccumulatedDelays.map(a => {
      return {
        id: a.displayName,
        title: a.displayName,
        delay: a.delay,
        value: a.averageDelay
      }
    })

    return {
      totalDelay: nrMinutesDelay,
      totalFlights: nrTotalTraffic,
      planned: nrPlannedTraffic,
      airborne: nrAirborneTraffic,
      landed: nrLandedTraffic,
      accumulatedDelays,
      liveDelays
    }
  },
  action: setStatistics
}

export const TICKER = 'TICKER'

updaterConfig[TICKER] = {
  apiGet: loadTicker,
  postProcess: function (payload) { return payload },
  action: dispatchTickerUpdate
}

export default updaterConfig
