// @flow

import * as React from 'react'
import { connect } from 'react-redux'
import './Ticker.css'

// move to reducers/index -> global state declaration...

import type { TickerState } from '../../redux/reducers/ticker'

type State = {
  ticker: TickerState
}

//

/*
 * Animates a ticker over the component through calls requestAnimationFrame() and translateX().
 * This was found to be... smoother, more visually appealing than CSS animations at the time of writing.
 * The browser calls requestAnimationFrame() when it's about to repaint the screen so up to 60 times per second,
 * therefore the implementation must be very efficient.
 *
 * No unit tests for now: it's so dependant on browser behaviour and visual rendering. Flow catches edge cases.
 */

type Props = {
  text: string
}

class Ticker extends React.Component<Props> {
  constructor (props) {
    super(props)
    let self: any = this
    self.step = this.step.bind(this)
  }

  // Purposefully not using React state because it is updated up to 60 times per second and changes do not affect
  // the DOM. It saves having to render the virtual DOM... to note that there is not change.
  // If the logic was to change, it would make sense to move these into React state.
  timestamp: ?number = null
  x = 0
  marquee = React.createRef()
  ruler = React.createRef()

  step (timestamp: number) {
    if(this.timestamp && this.marquee.current && this.ruler.current) {
      // how much to translate the text since last call for a consistent speed
      // the browser does not guarantee to call at a specific rate
      this.x -= ((timestamp - this.timestamp) * 0.12)
      // if disappeared on the left, re-appear from the right
      if(this.x < -this.ruler.current.offsetWidth) this.x = this.marquee.current.clientWidth

      this.marquee.current.style.transform = "translateX(" + this.x + "px)"
    }
    this.timestamp = timestamp
    window.requestAnimationFrame(this.step)
  }

  componentDidMount () {
    if(this.marquee.current) {
      this.x = this.marquee.current.clientWidth
    }
    window.requestAnimationFrame(this.step)
  }

  render () {
    // we need the 3 tags:
    // * the ticker, responsible for positioning and padding (see CSS)
    // * a marquee div that will be translated, for some reasons span do not translate well
    // * a ruler span that is used to calculate the total width of the text
    //   only a span can be either smaller or larger than the screen width, depending on the text
    return <div className="ticker">
      <div ref={this.marquee}><span ref={this.ruler} className="ruler">{this.props.text}</span></div>
    </div>
  }
}

function mapStateToProps (state: State): Props {
  return {
    text: state.ticker.text
  }
}

export default connect(mapStateToProps)(Ticker)
