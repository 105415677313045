/**
 * Configuration file for application
 */
const os = require('os')

const urls = {
  background: {
    arcGisBlack: 'https://server.arcgisonline.com/arcgis/rest/services/Canvas/World_Dark_Gray_Base/MapServer/wmts',
    arcGisWorld: 'https://server.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/wmts',
    mapTilerBasic: 'https://api.maptiler.com/maps/8bc21cd8-7842-4a4d-9755-58989bb7d115/256',
    mapTilerSatellite: 'https://api.maptiler.com/maps/2d1dbd71-a876-4f5f-a1d7-5c8dd5e26baa/256'
  },
  flights: 'https://int-api.nsv.eurocontrol.int:443/eurocontrol/flights',
  displayNames: 'https://int-api.nsv.eurocontrol.int:443/eurocontrol/displayNames',
  statistics: 'https://int-api.nsv.eurocontrol.int:443/eurocontrol/statistics',
  ticker: 'https://int-api.nsv.eurocontrol.int:443/eurocontrol/ticker',
  regulations24h: 'https://int-api.nsv.eurocontrol.int:443/eurocontrol/regulations/24h',
  timelapse: 'https://int-api.nsv.eurocontrol.int:443/eurocontrol/timelapse',
  timelapseScenario: 'https://int-api.nsv.eurocontrol.int:443/eurocontrol/timelapseScenario',
  clientLog: 'https://int-api.nsv.eurocontrol.int:443/eurocontrol/log',
  registerClient: 'https://int-api.nsv.eurocontrol.int:443/eurocontrol/registerClient',
  uploadFile: 'https://int-api.nsv.eurocontrol.int:443/eurocontrol/uploadScenarioFile',
  downloadFile: 'https://int-api.nsv.eurocontrol.int:443/eurocontrol/downloadScenarioFile',
  version: 'https://int-api.nsv.eurocontrol.int:443/eurocontrol/metadata/version'
}

// see http://bitbucket-nm.cfmu.corp.eurocontrol.int:7990/bitbucket/projects/DGSCREEN/repos/dgscreen-frontend/browse/src/app/information-bar/information.component.ts#459
const hostname = os.hostname()
const urlsDev = {
  background: {
    arcGisBlack: 'https://server.arcgisonline.com/arcgis/rest/services/Canvas/World_Dark_Gray_Base/MapServer/wmts',
    arcGisWorld: 'https://server.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/wmts',
    mapTilerBasic: 'https://api.maptiler.com/maps/8bc21cd8-7842-4a4d-9755-58989bb7d115/256',
    mapTilerSatellite: 'https://api.maptiler.com/maps/2d1dbd71-a876-4f5f-a1d7-5c8dd5e26baa/256'
  },
  flights: 'http://' + hostname + ':8443/eurocontrol/flights',
  displayNames: 'http://' + hostname + ':8443/eurocontrol/displayNames',
  statistics: 'http://' + hostname + ':8443/eurocontrol/statistics',
  ticker: 'http://' + hostname + ':8443/eurocontrol/ticker',
  regulations24h: 'http://' + hostname + ':8443/eurocontrol/regulations/24h',
  timelapse: 'http://' + hostname + ':8443/eurocontrol/timelapse',
  timelapseScenario: 'http://' + hostname + ':8443/eurocontrol/timelapseScenario',
  clientLog: 'http://' + hostname + ':8443/eurocontrol/log',
  registerClient: 'http://' + hostname + ':8443/eurocontrol/registerClient',
  uploadFile: 'http://' + hostname + ':8443/eurocontrol/uploadScenarioFile',
  downloadFile: 'http://' + hostname + ':8443/eurocontrol/downloadScenarioFile',
  version: 'http://' + hostname + ':8443/eurocontrol/metadata/version'
}

export default {
  apiMajorVersion: '0',
  debug: process.env.NODE_ENV === 'development',
  appTitle: 'ATFCM Situation Display', // not used at current version of app
  urls: process.env.NODE_ENV === 'development' ? urlsDev : urls,
  retryAttempts: 3, // api calls to be done before give up
  displayNameReloadIntervalInMilliSeconds: 60 * 1000,
  timelapseReloadIntervalInMilliSeconds: 15 * 60 * 1000,
  flightReloadIntervalInMilliSeconds: 5 * 60 * 1000,
  timeLabelUpdateIntervalInMilliSeconds: 1000,
  flightAnimationDelayInMilliSeconds: 2 * 1000,
  kpiTableSwitchIntervalInMilliSeconds: 2 * 60 * 1000,
  directorTableSwitchIntervalInMilliSeconds: 1 * 60 * 1000,
  flightTrailInSeconds: 300, // trace length in seconds
  mapAnimation: [7 * 1000, 20 * 1000], // in milliseconds: zoomed out, zoomed in
  apiPollingInterval: process.env.NODE_ENV === 'development' ? 30 * 1000 : 60 * 1000 // interval for backend api calls ms
}