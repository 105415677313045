import { PointLabelPosition } from '@luciad/ria/view/style/PointLabelPosition'
import * as Styling from '../styles/palette'
import IconFactory from './icon-factory'

export const DIRECTOR_OFFSET_LABEL = 40
export const OFFSET_LABEL = 32

export function getLabelofAverageDelay (averageDelay) {
  switch (averageDelay) {
    case 0:
      return 'REGULATED'
    case 1:
      return 'WARN'
    case 2:
      return 'ALERT'
    case 3:
      return 'CRITICAL'
    default:
      return 'NO_COLOUR'
  }
}

/**
 * Returns the airspace color using the specified average delay
 *
 * @param averageDelay the average delay
 *
 * @returns {string}
 */
export function getAirspaceColor (averageDelay) {
  switch (averageDelay) {
    case 0:
      return Styling.airspaceStyling.REGULATED
    case 1:
      return Styling.airspaceStyling.WARN
    case 2:
      return Styling.airspaceStyling.ALERT
    case 3:
      return Styling.airspaceStyling.CRITICAL
    default:
      return Styling.airspaceStyling.NO_COLOUR
  }
}

/**
 * Returns the airspace zOrder based on the specified average delay
 *
 * @param averageDelay the average delay
 *
 * @returns {number}
 */
export function getAirspaceZOrder (averageDelay) {
  switch (averageDelay) {
    case 0:
      return 3
    case 1:
      return 4
    case 2:
      return 5
    case 3:
      return 6
    default:
      return 2
  }
}

/**
 * Returns the rerouting airspace style based on the specified average delay
 *
 * @param averageDelay the average delay
 *
 * @returns {{fill: {color: string}, stroke: {color: string, width: number}, zOrder: number}}
 */
export function getReroutingAirspaceStyle () {
  const color = Styling.reroutingStyling
  return {
    fill: {
      color: color
    },
    stroke: {
      color: color,
      width: 1
    },
  }
}

/**
 * Returns the airspace style based on the specified average delay
 *
 * @param averageDelay the average delay
 *
 * @returns {{fill: {color: string}, stroke: {color: string, width: number}, zOrder: number}}
 */
export function getAirspaceStyle (averageDelay) {
  const color = getAirspaceColor(averageDelay)
  return {
    fill: {
      color: color
    },
    stroke: {
      color: color,
      width: 1
    },
    zOrder: getAirspaceZOrder(averageDelay),
    draped: true
  }
}

export function getDirectorAirspaceStyle (averageDelay) {
  return {
    fill: {
      color: Styling.directorAirspaceFillStyling[getLabelofAverageDelay(averageDelay)]
    },
    stroke: {
      color: Styling.directorAverageDelayPalette[getLabelofAverageDelay(averageDelay)],
      width: 2
    },
    zOrder: getAirspaceZOrder(averageDelay),
    draped: true
  }
}

export function getRegulationLabelColor (averageDelay) {
  switch (averageDelay) {
    case 0:
      return Styling.labelStyling.REGULATED
    case 1:
      return Styling.labelStyling.WARN
    case 2:
      return Styling.labelStyling.ALERT
    case 3:
      return Styling.labelStyling.CRITICAL
    default:
      return Styling.labelStyling.NO_COLOUR
  }
}

/**
 * Returns airport icon style based on the specified average delay
 *
 * @param averageDelay the average delay
 *
 * @returns {{url: string, width: string, height: string, zOrder: number, draped: boolean}}
 */
export function getAirportIcon (averageDelay) {
  const style = {
    width: '64px',
    height: '64px',
    zOrder: 8,
    draped: true
  }

  switch (averageDelay) {
    case 0:
      style['url'] = Styling.airportIconUrls.REGULATED
      break
    case 1:
      style['url'] = Styling.airportIconUrls.WARN
      break
    case 2:
      style['url'] = Styling.airportIconUrls.ALERT
      break
    case 3:
      style['url'] = Styling.airportIconUrls.CRITICAL
      break
    default:
      style['url'] = Styling.airportIconUrls.NORMAL
      break
  }

  return style
}

export function getDirectorAirportIcon (averageDelay) {
  const iconStyle = {
    width: '36',
    height: '36',
    haloColor: Styling.directorAirportIconStrokeStyling,
    lineColor: Styling.directorAverageDelayPalette[getLabelofAverageDelay(averageDelay)],
    haloWidth: '1'
  }
  const icon = IconFactory.BuildAirportIcon(iconStyle)

  return {
    width: '36px',
    height: '36px',
    zOrder: 8,
    draped: true,
    image: icon
  }

}

export function getDirectorAirplaneIcon (size) {
  const iconStyle = {
    width: '36',
    height: '36',
    haloColor: Styling.airplaneIconStrokeStyling,
    lineColor: Styling.airplaneIconFillStyling,
    haloWidth: '1'
  }
  const icon = IconFactory.BuildAirplaneIcon(iconStyle)

  return {
    width: size + 'px',
    height: size + 'px',
    offsetX: -5,
    offsetY: -5,
    zOrder: 10,
    draped: true,
    image: icon
  }
}

export function getAirplaneIcon (size) {
  let icon
  switch (size) {
    case 8:
      icon = Styling.planeIcons.PLANE_08
      break
    case 10:
      icon = Styling.planeIcons.PLANE_10
      break
    case 14:
      icon = Styling.planeIcons.PLANE_14
      break
    case 24:
      icon = Styling.planeIcons.PLANE_24
      break
    case 34:
      icon = Styling.planeIcons.PLANE_24
      break
    default:
      icon = Styling.planeIcons.VECTOR(size)
      break
  }

  return icon
}

/**
 * Returns aerodrome icon style based on the specified ariiving/departing type
 *
 * @param size icon size big or small
 * @param type the aerodrome type arriving/departing
 *
 * @returns {{url: string, width: string, height: string, zOrder: number, draped: boolean}}
 */
export function getAerodromeIcon (size, type) {
  switch (type) {
    case 'ARRIVING':
      if (size === 'SMALL') {
        return Styling.aerodromeIconUrls.ARIIVING_SMALL
      }
      return Styling.aerodromeIconUrls.ARRIVING_MEDIUM
    case 'DEPARTING':
      if (size === 'SMALL') {
        return Styling.aerodromeIconUrls.DEPARING_SMALL
      }
      return Styling.aerodromeIconUrls.DEPARTING_MEDIUM
    default:
      return Styling.aerodromeIconUrls.DEPARTING_MEDIUM
  }
}

/**
 * Returns aerodrome label color based on the specified ariiving/departing type
 *
 * @param type the aerodrome type arriving/departing
 *
 * @returns rgb color
 */
export function getAerodromeLabelColor (type) {
  return 'rgb(255,255,255)'
}

export function getAerodromeLabelStyle () {
  return {
    offset: 48 / 2 + 4,  // offset is half the size of the height of the icon + 4
    positions: [PointLabelPosition.SOUTH],
    priority: 0
  }
}

export function getPointLocationLabelStyle (labelPosition, offset) {
  return {
    offset: labelPosition === PointLabelPosition.SOUTH ? (offset / 2) : [0, 0],
    positions: [labelPosition],
    priority: 0
  }
}

export function getPolygonLocationLabelStyle () {
  return {
    inView: true,
    priority: 0
  }
}

export function getLocationLabelColor () {
  return 'rgb(255,255,255)'
}

/**
 * Returns aerodrome label color based on the specified ariiving/departing type
 *
 * @param type the aerodrome type arriving/departing
 *
 * @returns rgb color
 */
export function getAerodromeLabelShadow (type) {
  const color = getAerodromeLabelColor(type)

  return 'text-shadow: ' +
    '-2px -2px 0 ' + color + ',' +
    '-2px  0px 0 ' + color + ',' +
    '-2px  2px 0 ' + color + ',' +
    ' 0px -2px 0 ' + color + ',' +
    ' 0px  2px 0 ' + color + ',' +
    ' 2px -2px 0 ' + color + ',' +
    ' 2px  0px 0 ' + color + ',' +
    ' 2px  2px 0 ' + color
}