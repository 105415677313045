import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { KPIS_STATS } from '../../redux/actions/updater'
import connectTable from '../../redux/connectors/top-tables'
import Canary from '../elements/Canary'
import { TableIndicator, TableRow } from '../elements/TableIndicator'
import './display-table.css'

const KPIs = {
  liveDelays: {
    title: 'live delay situation',
    header: 'Display name',
    src: KPIS_STATS
  },
  accumulatedDelays: {
    title: 'most delayed locations',
    header: 'Location',
    src: KPIS_STATS
  }
}

const TableHeader = ({ header }) =>
  <div className='header'>
    <div>{header}</div>
    <div>Delay in<br/> minutes</div>
    <div>Avg. delay<br/>per flight</div>
  </div>

class DisplayTableController extends React.Component {

  constructor (props) {
    super(props)

    const { title, header, src } = KPIs[props.kpi]
    this.title = title
    this.header = header
    this.src = src
    this.kpi = props.kpi

    this.updater = props.updater
    this.updater.addSubscription(this.src)
    this.updater.start()
  }

  isLive () {
    const { values = {} } = this.props
    return values[this.kpi].online
  }

  componentWillUnmount () {
    this.updater.delSubscription(this.src)
  }

  render () {
    const { values = {} } = this.props

    const tableTitle = document.getElementById('tableTitle')
    if (tableTitle) {
      tableTitle.classList.add('tracking-in-expand')
    }

    return <div className='top5-table'>
      <div className='title' id='tableTitle'>
        {this.title}
      </div>

      <TableIndicator header={<TableHeader header={this.header}/>}>
        {values[this.kpi].value.map(r => <TableRow key={r.id} id={r.title} delay={r.delay} value={r.value}/>)}
      </TableIndicator>

      <Canary key='Canary'
              alive={!this.props.compatible || this.isLive()}
              color={!this.props.compatible ? 'red' : 'green'}/>
    </div>
  }
}

DisplayTableController.propTypes = {
  updater: PropTypes.object.isRequired,
  kpi: PropTypes.string.isRequired,
}

function mapStateToProps (state) {
  return {
    compatible: state.version.compatible
  }
}

export default connect(mapStateToProps)(connectTable(DisplayTableController))
